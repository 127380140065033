<div class="cis-submission-details">
  <!-- Masthead -->
  <div class="row">
    <!-- Masthead Header -->
    <div class="page-header col-7">
      <div class="masthead-header">
        <h1>Review CIS Submission Details</h1>
      </div>
    </div>
    <!-- Masthead Tools -->
    <div class="page-tools col-5">
      <div class="bold">Tools</div>
      <app-act-log-masthead [cisSubmission]="cisSubmission" [loggedInUser]="loggedInUser"></app-act-log-masthead> |
      <app-student-search-masthead></app-student-search-masthead> |
      <app-provision-student-masthead></app-provision-student-masthead>
    </div>
  </div>

  <!-- Display CIS submission general info -->
  <div>
    <app-cis-sub-gen-info [cisSubmission]="cisSubmission"></app-cis-sub-gen-info>
  </div>


  <!-- === Instructor Contact Information Card === -->
  <div class="margin-top-2-rem">
    <div class="row">
      <div class="col-9">
        <div class="card">
          <div class="card-body">
            <h2 class="card-title">Instructor Contact Information</h2>
            <p class="card-text">

            <div *ngFor="let contactInfo of cisSubmission.accommodationContactInfos, index as i;">
            <!-- Contact # -->
            <div class="margin-top-4-rem"> <h3>Contact {{i + 1}}</h3> </div>
              <!-- Name -->
              <div> <span class="fw-bold">Name:</span>
                   {{contactInfo.name ? contactInfo.name : contactInfo.firstname + ' ' + contactInfo.lastname}} </div>
              <!-- Email -->
              <div class="margin-top-2-rem"> <span class="fw-bold">Email:</span> {{contactInfo.email}} </div>
              <!-- Phone -->
              <div class="margin-top-2-rem"> <span class="fw-bold">Phone:</span>
                                                           {{contactInfo.phone ? contactInfo.phone : 'N/A'}} </div>
              <!-- Receive text -->
              <div class="margin-top-2-rem margin-bottom-2-rem"> <span class="fw-bold">Receive text:</span>
                                                           {{contactInfo.receiveText === true ? 'Yes' : 'N/A'}} </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- === Assessment Details === -->
  <div class="margin-top-2-rem">
    <div class="row">
      <div class="col-9">
        <div class="card">
          <div class="card-body">
            <h3 class="card-title">Assessment Details</h3>
            <p class="card-text">

              <!-- Assessment held on initial date -->
            <div><span class="fw-bold">Assessment held on initial date
                                  ({{cisSubmission.assessmentStartDate | date: 'yyyy-MM-dd'}})</span>:
                                             {{cisSubmission.assessmentHeldInd === true ? 'Yes' : 'No'}}</div>
            <!-- Assessment on a Different Date or No Upcoming Assessment -->
            <div class="margin-top-2-rem margin-left-2rem"><span class="fw-bold">
                                    Assessment on a Different Date or No Upcoming Assessment</span>:
                                           {{cisSubmission.upcomingAssessmentInd === true ? 'Yes' :
                                                   cisSubmission.upcomingAssessmentInd === false ? 'No' : 'N/A'}}</div>
            <!-- Explanation if no assessment -->
            <div class="margin-top-2-rem margin-left-4rem">
                                            <span class="fw-bold">Explanation for No Upcoming Assessment</span>: <br>
                                                     {{cisSubmission.explanationForNoAssessmentHeld || 'N/A'}}</div>



              <!-- *** Class Writing Schedule *** -->
              <div class="margin-top-4-rem"> <h3>Class Writing Schedule</h3> </div>

            <!-- Instructor Assessment Start Date -->
              <div> <span class="fw-bold">Assessment Start Date:</span>
                                             {{cisSubmission.assessmentStartDate | date: 'yyyy-MM-dd'}} </div>
              <!-- Instructor Assessment End Date -->
              <div class="margin-top-2-rem"><span class="fw-bold">Assessment End Date</span>:
                                             {{!cisSubmission.assessmentEndDate ?
                                                'N/A' : cisSubmission.assessmentEndDate | date: 'yyyy-MM-dd'}}</div>
              <!-- Instructor Assessment Start Time -->
              <div class="margin-top-2-rem"><span class="fw-bold">Assessment Start Time</span>:
                                             {{cisSubmission.assessmentStartTime | date:'h:mm a'}}</div>
              <!-- Instructor Assessment End Time -->
              <div class="margin-top-2-rem"><span class="fw-bold">Assessment End Time</span>:
                                             {{cisSubmission.assessmentEndTime | date:'h:mm a' || 'N/A'}}</div>
              <!-- Instructor Assessment Duration -->
              <div class="margin-top-2-rem"><span class="fw-bold">Duration</span>:
                          {{!cisSubmission.assessmentDuration ? 'N/A' : cisSubmission.assessmentDuration + 'min'}}</div>
              <!-- Timing Type (Timing and Submission) -->
              <div class="margin-top-2-rem"><span class="fw-bold">Timing Type</span>:
                                             {{Utils.assessmentTimingIndMap.get(cisSubmission.assessmentTimingInd)}}</div>

            <!-- *** Online vs. In-Person *** -->
            <div class="margin-top-4-rem"> <h3>Online vs. In-Person</h3> </div>

            <!-- Online Assessment -->
            <div><span class="fw-bold">Online Assessment:</span>
              {{cisSubmission.assessmentOnlineInd === true ? 'Yes' : 'No'}}</div>

              <!-- How is the assessment completed? -->
              <div class="margin-top-2-rem margin-left-2rem"><span class="fw-bold">How is the assessment completed?:</span>
                                   {{Utils.assessmentCompletionIndMap.get(cisSubmission.assessmentCompletionInd)}}</div>

            <!-- Online Type -->
            <div class="margin-top-2-rem margin-left-2rem"><span class="fw-bold">Online Type:</span>
                       {{onlineAssessmentTypeMap.get(cisSubmission.onlineAssessmentTypeId)?.onlineAssessmentType}}</div>

            <!-- Specify Type, if other -->
            <div class="margin-top-2-rem margin-left-4rem"><span class="fw-bold">Specify Type, if Other:</span> <br>
                        {{cisSubmission.onlineAssessmentTypeDetails || 'N/A'}}</div>

            <!-- Quercus/Crowdmark Consent -->
            <div class="margin-top-2-rem margin-left-4rem"><span class="fw-bold">Quercus/Crowdmark Consent:</span>
                       {{(cisSubmission.onlineAssmtPermissionToAtsInd === true ? 'Yes' : 'No') || 'N/A'}}</div>

            <!-- Is the online assessment published? -->
            <div class="margin-top-2-rem margin-left-6rem"><span class="fw-bold">Is the online assessment published?:</span>
              {{(cisSubmission.onlineAssmtPublishedInd === true ? 'Yes' : 'No') || 'N/A'}}</div>

            <!-- Name of Online Assessment -->
            <div class="margin-top-2-rem margin-left-8rem"><span class="fw-bold">Name of Online Assessment:</span> <br>
              {{cisSubmission.onlineAssmtTestName || 'N/A'}}</div>

            <!-- Lecture Before or After -->
            <div class="margin-top-2-rem margin-left-2rem"><span class="fw-bold">Lecture Before or After:</span>
                        {{Utils.lectureBeforeOrAfterMap.get(cisSubmission.lecBeforeOrAfterAssessmentInd) || 'N/A'}}</div>

              <!-- Time to Return to Class, if Yes -->
              <div class="margin-top-2-rem margin-left-4rem"><span class="fw-bold">Time to Return to Class, if After:</span>
                        {{!cisSubmission.lectureAfterReturnToClassBy ?
                                      'N/A' : cisSubmission.lectureAfterReturnToClassBy | date: 'h:mm a'}}</div>

            <!-- Group Component -->
            <div class="margin-top-2-rem margin-left-2rem"><span class="fw-bold">Group Component:</span>
                        {{cisSubmission.groupComponentForAssessmentInd === true ? 'Yes' :
                                      cisSubmission.groupComponentForAssessmentInd === false ? 'No' : 'N/A'}}</div>

              <!-- Time to Return to Class, if Yes -->
              <div class="margin-top-2-rem margin-left-4rem"><span class="fw-bold">Time to Return to Class, if Yes:</span>
                        {{!cisSubmission.groupComponentReturnToClassBy ?
                                     'N/A' : cisSubmission.groupComponentReturnToClassBy | date: 'h:mm a'}}</div>

            <!-- Delivery Details of Test Material (After the Assessment) ??? assessmentDetailsForAts-->
            <div class="margin-top-2-rem margin-left-2rem">
                                <span class="fw-bold">Delivery Details of Test Material (After the Assessment):</span>
                                          {{Utils.deliveryInstructionMap.get(cisSubmission.deliveryInstruction)}}</div>

            <!-- Assessment Location -->
            <div class="margin-top-2-rem margin-left-2rem"><span class="fw-bold">Assessment Location:</span> <br>
                                                        {{cisSubmission.assessmentLocation || 'N/A'}}</div>

            <!-- Special Instructions to Accommodated Testing Services -->
            <div class="margin-top-2-rem margin-left-2rem">
                              <span class="fw-bold">Special Instructions to Accommodated Testing Services:</span> <br>
                                                        {{cisSubmission.specialInstrForAssessmentHeld || 'N/A'}}</div>

            <!--Assessment Details for Accommodated Testing Services -->
            <div class="margin-top-2-rem margin-left-2rem">
                              <span class="fw-bold">Assessment Details for Accommodated Testing Services:</span> <br>
                                                        {{cisSubmission.assessmentDetailsForAts || 'N/A'}}</div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- === Accommodation Instructions Card === -->
  <div class="margin-top-2-rem">
    <div class="row">
      <div class="col-9">
        <div class="card">
          <div class="card-body">
            <h3 class="card-title">Accommodation Instructions</h3>
            <p class="card-text">

            <div> <span class="fw-bold">Special Instructions:</span> <br/>
                                            {{cisSubmission.specialInstructions}} </div>

            <!-- *** Aids & Scantron *** -->
            <div class="margin-top-4-rem"> <h3>Aids & Scantron</h3> </div>

            <!-- Aids Permitted -->
            <div> <span class="fw-bold">Aids Permitted:</span>
                                            {{cisSubmission.aidPermittedInd === true ? 'Yes' : 'No'}} </div>
              <div *ngFor="let aid of cisSubmission.accommodationAids, index as i;">
                <!-- Aid #: Aid category - type -->
                <div class="margin-top-2-rem margin-left-2rem"> <span class="fw-bold">Aid {{i + 1}}:</span>
                         {{aidMap.get(aid.aidTypeId)?.aidTypeCategory}} - {{aidMap.get(aid.aidTypeId)?.aidType}}</div>
                  <!-- Specify Further Details (Optional - shows only for certain aids) -->
                  <div class="margin-top-2-rem margin-left-4rem">
                                                          <span class="fw-bold">Further Details, if Other:</span><br>
                                                                        {{aid?.aidDetails || 'N/A'}}</div>
              </div>

            <!-- Scantron -->
            <div class="margin-top-2-rem"> <span class="fw-bold">Scantron:</span>
                                  {{cisSubmission.scantronMultipleChoiceInd === true ? 'Yes' : 'No'}} </div>
              <!-- Scantron Version -->
              <div class="margin-top-2-rem margin-left-2rem"> <span class="fw-bold">Scantron Version:</span>
                                   {{scantronMap.get(cisSubmission.scantronVersionId)?.scantronVersion || 'N/A'}}</div>
                <!-- Further Details, if Other -->
                <div class="margin-top-2-rem margin-left-4rem"> <span class="fw-bold">Further Details, if Other:</span><br>
                                   {{cisSubmission.scantronDetails || 'N/A'}}</div>

            <!-- Audio-Visual Component -->
            <div class="margin-top-4-rem"> <h3>Audio-Visual Component</h3> </div>

            <!-- === Audio-Visual required === -->
            <div> <span class="fw-bold">Audio-Visual Component Required:</span>
                                             {{cisSubmission.audiovisualRequiredInd === true ? 'Yes' : 'No'}} </div>

            <div class="margin-top-2-rem" *ngFor=" let av of cisSubmission.accommodationAudiovisuals, index as i;">
              <!-- AV Component # -->
              <div class="margin-top-4-rem"> <h4>AV Component {{i + 1}}</h4> </div>
              <!-- Is the timing of the audiovisual component strict? -->
              <div> <span class="fw-bold">Strict Timing:</span>
                                                     {{av.audiovisualTimingStrictInd === true ? 'Yes' : 'No'}} </div>
                <!-- What is the total length of the audiovisual component? -->
                <div class="margin-top-2-rem margin-left-2rem">
                                      <span class="fw-bold">Total length of AV component:</span> <br>
                                                                  {{av.audiovisualLength || 'N/A'}} </div>
                <!-- When should the audiovisual component be administered? -->
                <div class="margin-top-2-rem margin-left-2rem">
                                      <span class="fw-bold">When the AV component be administered:</span> <br>
                                                                   {{av.audiovisualWhenShouldBeAdmin || 'N/A'}} </div>

              <!-- Audiovisual Component -->
              <div class="margin-top-2-rem"> <span class="fw-bold">Type:</span>
                                             {{audiovisualMap.get(av.audiovisualCompId)?.audiovisualComp}} </div>

              <!-- Specify further details (for Other AV only) -->
              <div class="margin-top-2-rem margin-left-2rem">
                                     <span class="fw-bold">Further Details, if Other:</span> <br>
                                                                   {{av.audiovisualDetailsForOther || 'N/A'}} </div>

                <!-- How long should each [i.e. comp: slide/clip] be shown? -->
                <div class="margin-top-2-rem margin-left-2rem">
                                     <span class="fw-bold">How long each slide/clip should be shown:</span> <br>
                                                                   {{av.audiovisualHowLongToShow || 'N/A'}} </div>
                <!-- How many times should each [i.e. comp: slide/clip] be shown? -->
                <div class="margin-top-2-rem margin-left-2rem">
                                      <span class="fw-bold">How many times each slide/clip should be shown:</span> <br>
                                                                   {{av.audiovisualHowManyTimesToShow || 'N/A'}} </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- === Assessment File(s) Card === -->
  <div class="margin-top-2-rem">
    <div class="row">
      <div class="col-9">
        <div class="card">
          <div class="card-body">
            <h2 class="card-title">Assessment File(s)</h2>
            <p class="card-text">

            <!-- File Upload -->
            <div class="margin-bottom-2-rem"> <span class="fw-bold">File Upload:</span>
                                {{cisSubmission.requiresFileUpload === true ? 'Yes' : 'No'}}</div>

            <div *ngFor="let file of cisSubmission.files, index as i;">
              <!-- File # -->
              <div class="margin-top-4-rem"> <h3>File {{i + 1}}</h3> </div>
              <!-- Name -->
              <div> <span class="fw-bold">Name:</span> {{file.documentName}} </div>
              <!-- Location -->
              <div class="margin-top-2-rem"> <span class="fw-bold">Location:</span> {{file.documentLocation}} </div>
              <!-- Number of Pages -->
              <div class="margin-top-2-rem"> <span class="fw-bold">Number of Pages:</span> {{file.noOfPages}} </div>
              <!-- Document Modified Date -->
              <div class="margin-top-2-rem margin-bottom-2-rem"> <span class="fw-bold">Document Modified Date:</span>
                                             {{file.documentModifiedDate | date: 'yyyy-MM-dd'}}</div>
            </div>

            <!-- Printing Options -->
            <div class="margin-top-4-rem">
              <h3>Printing Options</h3>
              <!-- Using Crowdmark -->
              <div> <span class="fw-bold">Using Crowdmark:</span>
                {{cisSubmission.printOptions.crowdMark === true ? 'Yes' :
                                             cisSubmission.printOptions.crowdMark === false ? 'No' : 'N/A'}}</div>
              <!-- Page Size -->
              <div class="margin-top-2-rem"> <span class="fw-bold">Page Size:</span>
                                                 {{Utils.printPageSize(cisSubmission.printOptions.pageSize)}} </div>
              <!-- Print 2-Sided -->
              <div class="margin-top-2-rem"> <span class="fw-bold">Print 2-Sided:</span>
                {{cisSubmission.printOptions.twoSided === true ? 'Yes' :
                                             cisSubmission.printOptions.twoSided === false ? 'No' : 'N/A'}}</div>
              <!-- Color Settings -->
              <div class="margin-top-2-rem"> <span class="fw-bold">Color Settings:</span>
                                                 {{Utils.printColour(cisSubmission.printOptions.colorSettings)}} </div>
              <!-- Special Instructions for Printing -->
              <div class="margin-top-2-rem"> <span class="fw-bold">Special Instructions for Printing:</span><br>
                                                 {{cisSubmission.specialPrintInstructions}}</div>
            </div>

            <!-- *** Video URLs *** -->
            <div class="margin-top-4-rem">
              <h3>Video URLs</h3>
              <div *ngFor="let link of cisSubmission.links, index as i;">
                <!-- Link # -->
                <div class="margin-top-4-rem"> <h4>Link {{i + 1}}</h4> </div>
                <!-- Location -->
                <div> <span class="fw-bold">Location:</span> {{link.documentLocation}} </div>
                <!-- Link Modified Date -->
                <div class="margin-top-2-rem margin-bottom-2-rem"> <span class="fw-bold">Link Modified Date:</span>
                                                  {{link.documentModifiedDate | date: 'yyyy-MM-dd'}}
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div> <!-- end of cards -->

  <!-- Review Notes -->
  <div class="margin-top-4-rem"> <h2>Review Notes</h2> </div>

  <!-- Display existing ATS notes table -->
  <div class="margin-top-2-rem">
    <div class="row">
      <div class="col-9">
        <app-review-notes-table [reviewNotes]="cisSubmission._atsNotes"></app-review-notes-table>
      </div>
    </div>
  </div>

  <!-- Notes for this Submission (Optional) -->
  <div class="margin-top-2-rem"> <span class="fw-bold">Notes for this Submission (Optional)</span></div>

  <div>
    <div class="col-9">
        <textarea class="" id="atsCisSubmissionNotes"
                  [formControl]="atsCisSubmissionNotesCtrl"
                  rows='2' cols='95'>
        </textarea>
    </div>
  </div>

  <div class="margin-top-1-rem">
    <sis-button size="small" (clicked)="addAtsNote()">Add Note</sis-button>
  </div>

  <!-- Buttons -->
  <div class="margin-top-4-rem" *ngIf="cisSubmission._reviewer && cisSubmission._reviewer === loggedInUser">
    <span class="margin-right-2rem" *ngIf="cisSubmission._reviewStatus != ReviewStatusCode.REVIEWED">
      <sis-button size="medium"
                  (clicked)="setReviewStatus(cisSubmission.atsTestDefinitionId, ReviewStatusCode.REVIEWED)">
        Mark as Reviewed
      </sis-button>&nbsp;
    </span>

    <span class="margin-right-2rem" *ngIf="cisSubmission._reviewStatus != ReviewStatusCode.NOT_REVIEWED">
      <sis-button size="medium"
                  (clicked)="setReviewStatus(cisSubmission.atsTestDefinitionId, ReviewStatusCode.NOT_REVIEWED)">
        Mark as Not Reviewed
      </sis-button>&nbsp;
    </span>

    <span class="margin-right-2rem">
      <ng-template #dropdownBodyTemplate>
        <button *ngIf="cisSubmission._reviewStatus != ReviewStatusCode.PENDING_CONFIRMATION"
                (click)="setReviewStatus(cisSubmission.atsTestDefinitionId, ReviewStatusCode.PENDING_CONFIRMATION)">
          Pending Confirmation
        </button>
        <button *ngIf="cisSubmission._reviewStatus != ReviewStatusCode.PENDING_CONFIRMATION_AWAITING_FILE"
                (click)="setReviewStatus(cisSubmission.atsTestDefinitionId, ReviewStatusCode.PENDING_CONFIRMATION_AWAITING_FILE)">
          Pending Confirmation - Awaiting File
        </button>
      </ng-template>
      <sis-button
        size="medium"
        [dropdownBodyTemplate]="dropdownBodyTemplate"
      >
        Mark as Pending Confirmation
      </sis-button>
    </span>

  </div>

  <!-- Back to CIS Submissions Dashboard -->
  <div class="margin-top-4-rem">
    <a [routerLink]="['/cis-submissions']">Back to CIS Submissions Dashboard</a>
  </div>
</div>
