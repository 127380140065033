import { Injectable } from '@angular/core';

/**
 * Application wide service to be used for storing/retrieving/removing user specific data.
 */
@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {

  localStorage: Storage;

  constructor() {
    this.localStorage = window.localStorage;
  }

  get(key: string): any {

    if (this.isLocalStorageSupported) {
      return JSON.parse(localStorage.getItem(key));
    }
    return null;
  }

  set(key: string, value: any): boolean {

    if (this.isLocalStorageSupported) {
      this.localStorage.setItem(key, JSON.stringify(value));
      return true;
    }
    return false;
  }

  remove(key: string): boolean {

    if (this.isLocalStorageSupported) {
      this.localStorage.removeItem(key);
      return true;
    }
    return false;
  }

  // private method
  get isLocalStorageSupported() {
    return !!this.localStorage;
  }

}
