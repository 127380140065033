import { Component } from '@angular/core';
import {
  StudentSearchModalComponent
} from "../../../app-student-profile/student-search-modal/student-search-modal.component";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {Router} from "@angular/router";

@Component({
  selector: 'app-student-search-masthead',
  templateUrl: './student-search-masthead.component.html',
  styleUrls: ['./student-search-masthead.component.scss']
})
export class StudentSearchMastheadComponent {

  constructor(private modalService: NgbModal, private router: Router) { }

  openStudentSearchModal() {
    const modalRef = this.modalService.open(StudentSearchModalComponent).result.then((atsStudentId) => {

      if (atsStudentId) {
        this.router.navigateByUrl('student-profile/' + atsStudentId);
      }
    }, () => {
    });
  }

}
