import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {FormControl} from "@angular/forms";
import {MatTableDataSource} from "@angular/material/table";
import {CourseContactRec} from "../course-contacts-dashboard/course-contacts-dashboard.component";
import {InstructorEmailingUtils} from "../instructor-emailing-utils";
import moment from "moment/moment";
import {LogService} from "../../common/services/log-service.service";
import {CommonUtils} from "../../common/utils/common-utils";

@Component({
  selector: 'app-course-contacts-filter',
  templateUrl: './course-contacts-filter.component.html',
  styleUrls: ['./course-contacts-filter.component.scss']
})
export class CourseContactsFilterComponent implements OnInit, OnChanges {

  @Input() dataSource: MatTableDataSource<CourseContactRec>;

  // Holds the text typed in the filter input. Used for filtering the course.
  textFilter: string;

  // Used to trigger the filtering. Always has the value of last typed/selected value.
  filter: string;

  // Hold the typed or selected value from the drop-down.
  courseFilter: string;
  levelOfStudyFilter: string;
  contactAndEmailFilter: string;
  contactTypeFilter: string;
  validStatusFilter: string;

  private currentDate: moment.Moment;

  // Filters
  courseCtrl = new FormControl('');
  levelOfStudyCtrl = new FormControl('');
  contactAndEmailCtrl = new FormControl('');
  contactTypeCtrl = new FormControl('');
  validStatusCtrl = new FormControl('');

  constructor(private logger: LogService) {
  }

  ngOnInit(): void {

    this.currentDate = moment().startOf('day');

    this.levelOfStudyCtrl.valueChanges.subscribe((val: string) => {
      this.logger.debug(`=> value changed to: ${val}`, `${this.constructor.name}`);

      this.levelOfStudyFilter = val;
      this.dataSource.filter = 'LEVEL_OF_STUDY_FILTER:' + val;
    });

    this.contactTypeCtrl.valueChanges.subscribe( (val: string) => {
      this.logger.debug(`=> value changed to: ${val}`, `${this.constructor.name}`);

      this.contactTypeFilter = val;
      this.dataSource.filter = 'CONTACT_TYPE_FILTER:' + val;
    });

    this.validStatusCtrl.valueChanges.subscribe( (val: string) => {
      this.logger.debug(`=> value changed to: ${val}`, `${this.constructor.name}`);

      this.validStatusFilter = val;
      this.dataSource.filter = 'VALID_STATUS_FILTER:' + val;
    });

    this.contactAndEmailCtrl.valueChanges.subscribe( (val: string) => {
      this.logger.debug(`=> value changed to: ${val}`, `${this.constructor.name}`);

      this.contactAndEmailFilter = val;
      this.dataSource.filter = 'CONTACT_AND_EMAIL_FILTER:' + val;
    });
  }

  ngOnChanges(changes: SimpleChanges): void {

    // Once data source input is received from the parent, do the filtering.
    if (this.dataSource != null && changes['dataSource'].currentValue != null) {
      this.dataSource.filter = this.textFilter;
      this.dataSource.filterPredicate = (data:CourseContactRec, filter: string): boolean => {

        this.logger.debug(`=> filter: ${filter}`, `${this.constructor.name}`);

        return this._filterByCourse(data, this.courseFilter)
                && this._filterByLevelOfStudy(data, this.levelOfStudyFilter)
                && this._filterByContactNameAndEmail(data, this.contactAndEmailFilter)
                && this._filterByContactType(data, this.contactTypeFilter)
                && this._filterByValidStatus(data, this.validStatusFilter);
      }
    }
  }

  applyTextFilter($event: KeyboardEvent): void {
    const val = ($event.target as HTMLInputElement).value;
    this.courseFilter = val.trim();
    this.dataSource.filter = 'COURSE_FILTER:' + val;
  }

  _filterByCourse(data: CourseContactRec, filter: string): boolean {
    if (!filter)  return true;

    return this.CommonUtils.checkMatch(InstructorEmailingUtils.formatCourse(data.course), filter);
  }

  _filterByContactNameAndEmail(data: CourseContactRec, filter: string) {
    if (!filter)  return true;

    return this.CommonUtils.checkMatch(data.contactName.concat(' ', data.emailAddress), filter);
  }

  // Possible filter values: LevelOfStudy.UNDERGRADUATE, LevelOfStudy.GRADUATE, LevelOfStudy.BOTH
  _filterByLevelOfStudy(data: CourseContactRec, filter: string): boolean {
    return !filter ? true : data.levelOfStudyVal === filter;
  }

  // Possible filter values: '', 'COURSE_COORDINATOR', 'DEPARTMENT_ADMINISTRATOR', 'INSTRUCTOR', 'PROGRAM_COORDINATOR', 'TEACHING_ASSISTANT', 'OTHER'
  _filterByContactType(data: CourseContactRec, filter: string): boolean {
    return !filter ? true : data.contactTypeVal === filter;
  }

  // Possible filter values: '', 'CURRENTLY_VALID', 'EXPIRED'
  _filterByValidStatus(data: CourseContactRec, filter: string): boolean {

    if (!filter) return true;

    let selected: boolean;

    const expiryDate: moment.Moment = !data.validUntil ? null : moment(data.validUntil);

    switch (filter) {
      case '':
        selected = true;
        break;
      case 'CURRENTLY_VALID':
        selected = expiryDate === null || !this.currentDate.isAfter(expiryDate);
        break;
      case 'EXPIRED':
        selected = expiryDate != null && this.currentDate.isAfter(expiryDate);
        break;
      default:
        selected = true;
    }
    return selected;
  }

  resetFilters() {
    this.courseCtrl.setValue('');
    this.levelOfStudyCtrl.setValue('');
    this.contactAndEmailCtrl.setValue('');
    this.contactTypeCtrl.setValue('');
    this.validStatusCtrl.setValue('');

    // This will trigger the filtering refreshing.
    this.dataSource.filter = '';

  }

  protected readonly InstructorEmailingUtils = InstructorEmailingUtils;
  protected readonly Utils = InstructorEmailingUtils;
  protected  readonly CommonUtils = CommonUtils;
}
