import {Component} from '@angular/core';
import {AuthService} from "../../common/services/auth.service";

@Component({
  selector: 'app-designate-dashboard',
  templateUrl: './designate-dashboard.component.html',
  styleUrls: ['./designate-dashboard.component.scss']
})
export class DesignateDashboardComponent {
   isUserDesginatesAdmin = false;

  constructor(private authService: AuthService) {
    this.isUserDesginatesAdmin = authService.isUserDesginatesAdmin();
  }
}
