<div>
  <!-- Masthead -->
  <div class="row">
    <!-- Masthead Header -->
    <div class="page-header col-7">
      <div class="masthead-header">
        <h1>CIS Submissions Dashboard</h1>
      </div>
    </div>
    <!-- Masthead Tools -->
    <div class="page-tools col-5">
      <app-masthead-tools></app-masthead-tools>
    </div>
  </div>

  <div class="row">
    <div class="col-8">
      This is where you can review assessment details submitted by instructors from the Course Information System (CIS).
    </div>
  </div>

  <!-- Search title -->
  <div class="row">
    <div class="page-header col-8">
      <div class="margin-top-4-rem">
        <h1>Search for CIS Submissions</h1>
      </div>
    </div>
  </div>

  <!-- Search by Class Writing Date -->
  <div>
    <div class="row">
      <div class="col-5">
        <div><h4>Search by Class Writing Date Submitted by Instructor</h4></div>
      </div>
    </div>
    <div class="row ">
      <div class="col-2">
        <!-- start date -->
        <strong>Date Range Start <br>(yyyy-mm-dd)</strong>
        <app-datepicker [formControl]="searchByRangeStartDateCtrl"></app-datepicker>
        <app-validation-message [control]="searchByRangeStartDateCtrl"></app-validation-message>
      </div>
      <!-- end date -->
      <div class="col-2">
        <strong>Date Range End <br>(yyyy-mm-dd)</strong>
        <app-datepicker [formControl]="searchByRangeEndDateCtrl"></app-datepicker>
        <app-validation-message [control]="searchByRangeEndDateCtrl"></app-validation-message>
      </div>

    </div>
  </div>

  <div class="row margin-top-2-rem">
    <!-- Search buttons -->
    <div class="col-12">
      <!-- Search button -->
      <span>
        <sis-button visualType="primary" size="medium" (clicked)="search()">Search</sis-button>
      </span>
      <!-- Reset Search button -->
      <span class="margin-left-2rem">
        <sis-button visualType="secondary" size="medium" (clicked)="resetSearch()">Reset Search</sis-button>
      </span>
    </div>
  </div>

  <!-- CIS Submissions -->
  <div class="margin-top-40-px">
    <h2>CIS Submissions ({{dataSource?.data?.length}})</h2>
  </div>

  <!-- button: View Statistics for Date Range -->
  <div class="row margin-top-2-rem">
    <div class="col-3">
      <sis-button visualType="secondary" size="small" (clicked)="viewStatistics()"> View Statistics for Date Range </sis-button>
    </div>
  </div>

  <!-- Search info -->
  <div class="row margin-top-2-rem">
    <div class="col-8">
      The table below displays CIS Submissions that correspond to the date range you specified. Use the filters to
      further refine your search.
      <br><br>
      For each submission, you can assign yourself as a reviewer and review their details. If you have marked the
      Review Status as Reviewed, Pending Confirmation, or Pending Confirmation - Awaiting file, you will not be able
      to unassign yourself from the submission. If you want to unassign yourself, you will need to mark the
      Review Status as Not Reviewed.
    </div>
  </div>

  <!-- CIS Submissions filter -->
  <div>
    <app-cis-submissions-filter [dataSource]="dataSource"></app-cis-submissions-filter>
  </div>

  <div class="row margin-top-4-rem margin-right-4rem">
    <div class="col-12">

      <table mat-table [dataSource]="dataSource" matSort
             cdkDropList cdkDropListOrientation="horizontal" (cdkDropListDropped)="tableDrop($event)"
             class="table table-container table-responsive">

        <!-- ATS Assignee -->
        <ng-container matColumnDef="atsAssignee">
          <th mat-header-cell *matHeaderCellDef mat-sort-header
              cdkDrag cdkDragLockAxis="x" cdkDragBoundary="mat-header-row"> ATS Assignee </th>
          <td mat-cell *matCellDef="let element">
            <div class="flex-container">
              <div>
                {{element._reviewer || 'None'}} &nbsp;&nbsp;&nbsp;
              </div>
              <!-- Assign reviewer button -->
              <div *ngIf="element._reviewer !== loggedInUser">
                <sis-button visualType="secondary" size="small"
                            (clicked)="assignReviewer(element.atsTestDefinitionId)">
                  Assign
                </sis-button>
              </div>
              <!-- Unassign reviewer button -->
              <div *ngIf="canUnassign(element._reviewer, element._reviewStatus)">
                <sis-button visualType="secondary" size="small"
                            (clicked)="unassignReviewer(element.atsTestDefinitionId)">
                  Unassign
                </sis-button>
              </div>
            </div>
          </td>
        </ng-container>

        <!-- Review Status -->
        <ng-container matColumnDef="reviewedStatus">
          <th mat-header-cell *matHeaderCellDef mat-sort-header
              cdkDrag cdkDragLockAxis="x" cdkDragBoundary="mat-header-row"> Review Status </th>
          <td mat-cell *matCellDef="let element">
            {{Utils.reviewStatusCodeMap.get(element._reviewStatus || ReviewStatusCode.NOT_REVIEWED)}}
          </td>
        </ng-container>

        <!-- Class Writing Date -->
        <ng-container matColumnDef="classWritingDate">
          <th mat-header-cell *matHeaderCellDef mat-sort-header
              cdkDrag cdkDragLockAxis="x" cdkDragBoundary="mat-header-row"> Class Writing Start Date </th>
          <td mat-cell *matCellDef="let element">
            {{element.assessmentStartDate | date:'yyyy-MM-dd'}}

            <!-- Popover button -->
            <ng-container>
              <ng-template #popoverBody let-classWritingDates="classWritingDates">
                There is a discrepancy between this test definition's class writing date
                (<span class="fw-bold">{{classWritingDates.studStartDate}}</span>) and the instructor's submission
                (<span class="fw-bold">{{classWritingDates.instrStartDate}}</span>).
              </ng-template>

              <ng-container *ngIf="element._studInstrChanges?.differentDates === true">
                <sis-popover-button
                  titleText="Class Writing Start Date Discrepancy"
                  [body]="popoverBody"
                  [context]="{
                    classWritingDates: {
                      studStartDate: element._studInstrChanges?.studStartDate,
                      instrStartDate: element._studInstrChanges?.instrStartDate
                    }
                  }"
                  ariaLabel="Learn about this registration status."
                >
                </sis-popover-button>
              </ng-container>
            </ng-container>

          </td>
        </ng-container>



        <!-- Class Writing Start Time -->
        <ng-container matColumnDef="classWritingStartTime">
          <th mat-header-cell *matHeaderCellDef mat-sort-header
              cdkDrag cdkDragLockAxis="x" cdkDragBoundary="mat-header-row"> Class Writing Start Time </th>
          <td mat-cell *matCellDef="let element">
            {{element.assessmentStartTime | date:'h:mm a'}}

            <!-- Popover button -->
            <ng-container>
              <ng-template #popoverBody let-classWritingTimes="classWritingTimes">
                There is a discrepancy between this test definition's class writing start time
                (<span class="fw-bold">{{classWritingTimes.studStartTime}}</span>) and the instructor's submission
                (<span class="fw-bold">{{classWritingTimes.instrStartTime}}</span>).
              </ng-template>

              <ng-container *ngIf="element._studInstrChanges?.differentTimes === true">
                <sis-popover-button
                  titleText="Class Writing Start Time Discrepancy"
                  [body]="popoverBody"
                  [context]="{
                    classWritingTimes: {
                      studStartTime: element._studInstrChanges?.studStartTime,
                      instrStartTime: element._studInstrChanges?.instrStartTime
                    }
                  }"
                  ariaLabel="Learn about this registration status."
                >
                </sis-popover-button>
              </ng-container>
            </ng-container>

          </td>
        </ng-container>

        <!-- Class Writing End Time -->
        <ng-container matColumnDef="classWritingEndTime">
          <th mat-header-cell *matHeaderCellDef mat-sort-header
              cdkDrag cdkDragLockAxis="x" cdkDragBoundary="mat-header-row"> Class Writing End Time </th>
          <td mat-cell *matCellDef="let element">{{(element.assessmentEndTime | date:'h:mm a') || 'N/A'}}</td>
        </ng-container>

        <!-- Duration -->
        <ng-container matColumnDef="duration">
          <th mat-header-cell *matHeaderCellDef mat-sort-header
              cdkDrag cdkDragLockAxis="x" cdkDragBoundary="mat-header-row"> Duration (minutes) </th>
          <td mat-cell *matCellDef="let element">{{element.duration ? element.duration + 'min' : 'N/A'}}</td>
        </ng-container>

        <!-- Course, Section -->
        <ng-container matColumnDef="courseSection">
          <th mat-header-cell *matHeaderCellDef mat-sort-header
              cdkDrag cdkDragLockAxis="x" cdkDragBoundary="mat-header-row"> Course, Section </th>
          <td mat-cell *matCellDef="let element">{{Utils.formatCourse(element)}}</td>
        </ng-container>

        <!-- Type -->
        <ng-container matColumnDef="type">
          <th mat-header-cell *matHeaderCellDef mat-sort-header
              cdkDrag cdkDragLockAxis="x" cdkDragBoundary="mat-header-row"> Type </th>
          <td mat-cell *matCellDef="let element">{{element.testSubtype}} {{element.testType}}</td>
        </ng-container>

        <!-- Online vs In-Person -->
        <ng-container matColumnDef="onlineVsInPerson">
          <th mat-header-cell *matHeaderCellDef mat-sort-header
              cdkDrag cdkDragLockAxis="x" cdkDragBoundary="mat-header-row"> Online vs In-Person </th>
          <td mat-cell *matCellDef="let element">{{Utils.onlineVsInPersonMap.get(element.__onlineVsInPerson) || 'N/A'}}

            <!-- Popover button -->
            <ng-container>
              <ng-template #popoverBody let-onlineVsInPerson="onlineVsInPerson">
                There is a discrepancy between this test definition's class writing details
                ({{Utils.onlineVsInPersonMap.get(onlineVsInPerson.previous)}}) and the instructor's submission
                ({{Utils.onlineVsInPersonMap.get(element.__onlineVsInPerson)}}).
              </ng-template>

              <ng-container *ngIf="element._accommInstrChanges?.propValMap.__onlineVsInPerson">
                <sis-popover-button
                  titleText="Online vs In-Person Discrepancy"
                  [body]="popoverBody"
                  [context]="{
                    onlineVsInPerson: {
                      previous: element._accommInstrChanges?.propValMap.__onlineVsInPerson.previousVal
                    }
                  }"
                  ariaLabel="Learn about this registration status."
                >
                </sis-popover-button>
              </ng-container>
            </ng-container>

          </td>
        </ng-container>

        <!-- Quercus Consent -->
        <ng-container matColumnDef="quercusConsent">
          <th mat-header-cell *matHeaderCellDef mat-sort-header
              cdkDrag cdkDragLockAxis="x" cdkDragBoundary="mat-header-row"> Quercus/Crowdmark Consent </th>
          <td mat-cell *matCellDef="let element">
            {{element.quercusPermissionToAtsInd === true ? 'Yes' :
                                           (element.quercusPermissionToAtsInd === false ? 'No' : 'N/A')}}
          </td>
        </ng-container>

        <!-- ATS Test Definition ID -->
        <ng-container matColumnDef="testDefinitionId">
          <th mat-header-cell *matHeaderCellDef mat-sort-header
              cdkDrag cdkDragLockAxis="x" cdkDragBoundary="mat-header-row"> ATS Test Definition ID </th>
          <td mat-cell *matCellDef="let element">{{element.atsTestDefinitionId}}</td>
        </ng-container>

        <!-- Number of Bookings -->
        <ng-container matColumnDef="numberOfBookings">
          <th mat-header-cell *matHeaderCellDef mat-sort-header
              cdkDrag cdkDragLockAxis="x" cdkDragBoundary="mat-header-row"> Number of Bookings </th>
          <td mat-cell *matCellDef="let element">{{element.numberOfBookings}}</td>
        </ng-container>

        <!-- Assessment Timing Type -->
        <ng-container matColumnDef="assessmentTimingType">
          <th mat-header-cell *matHeaderCellDef mat-sort-header
              cdkDrag cdkDragLockAxis="x" cdkDragBoundary="mat-header-row"> Assessment Timing Type </th>
          <td mat-cell *matCellDef="let element">{{Utils.determineAssessmentTimingType(element.assessmentTimingInd)}}</td>
        </ng-container>

        <!-- Lecture Before or After -->
        <ng-container matColumnDef="lectureBeforeOfAfter">
          <th mat-header-cell *matHeaderCellDef mat-sort-header
              cdkDrag cdkDragLockAxis="x" cdkDragBoundary="mat-header-row"> Lecture Before or After </th>
          <td mat-cell *matCellDef="let element">
                              {{Utils.lectureBeforeOrAfterMap.get(element.lecBeforeOrAfterAssessmentInd) || 'N/A'}}</td>
        </ng-container>

        <!-- Group Component -->
        <ng-container matColumnDef="groupComponent">
          <th mat-header-cell *matHeaderCellDef mat-sort-header
              cdkDrag cdkDragLockAxis="x" cdkDragBoundary="mat-header-row"> Group Component </th>
          <td mat-cell *matCellDef="let element">{{element.groupComponentForAssessmentInd === true ? 'Yes'
                                                      : (element.groupComponentForAssessmentInd === false ? 'No' : 'N/A')}}
          </td>
        </ng-container>

        <!-- AV Component Required -->
        <ng-container matColumnDef="avCompRequired">
          <th mat-header-cell *matHeaderCellDef mat-sort-header
              cdkDrag cdkDragLockAxis="x" cdkDragBoundary="mat-header-row"> AV Component Required </th>
          <td mat-cell *matCellDef="let element">{{element.audiovisualRequiredInd === true ? 'Yes'
                                                       : element.audiovisualRequiredInd === false ? 'No' : 'N/A'}}</td>
        </ng-container>

        <!-- Test Material Delivery Method -->
        <ng-container matColumnDef="testMaterialDeliveryMethod">
          <th mat-header-cell *matHeaderCellDef mat-sort-header
              cdkDrag cdkDragLockAxis="x" cdkDragBoundary="mat-header-row"> Test Material Delivery Method </th>
          <td mat-cell *matCellDef="let element">{{Utils.deliveryInstructionMap.get(element.deliveryInstruction) || 'N/A'}}</td>
        </ng-container>

        <!-- File Uploaded -->
        <ng-container matColumnDef="fileUploaded">
          <th mat-header-cell *matHeaderCellDef mat-sort-header
              cdkDrag cdkDragLockAxis="x" cdkDragBoundary="mat-header-row"> File Uploaded </th>
          <td mat-cell *matCellDef="let element">{{element.requiresFileUpload === true ? 'Yes'
                                                      : element.requiresFileUpload === false ? 'No' : 'N/A'}}</td>
        </ng-container>

        <!-- Submission Status -->
        <ng-container matColumnDef="cisSubmissionStatus">
          <th mat-header-cell *matHeaderCellDef mat-sort-header
              cdkDrag cdkDragLockAxis="x" cdkDragBoundary="mat-header-row"> CIS Submission Status </th>
          <td mat-cell *matCellDef="let element">{{Utils.determineCisSubmissionStatus(element.workflowStatus?.statusCode)}}</td>
        </ng-container>

        <!-- [Placeholder for buttons] -->
        <ng-container matColumnDef=" " [sticky]="true" [stickyEnd]="true">
          <th mat-header-cell *matHeaderCellDef> </th>
          <td mat-cell *matCellDef="let element">
            <sis-button size="small" (clicked)="reviewDetails(element.atsTestDefinitionId)">Review Details</sis-button>
          </td>
        </ng-container>


        <tr mat-header-row *matHeaderRowDef="displayColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayColumns;"></tr>

      </table>
    </div>

    <div class="">
      <mat-paginator [pageSize]="20"
                     [pageSizeOptions]="[20, 40]" showFirstLastButtons="true">
      </mat-paginator>
    </div>
  </div>

</div>
