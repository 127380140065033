
<div>
  <!-- Masthead -->
  <div class="row">
    <!-- Masthead Header -->
    <div class="page-header col-7">
      <div class="masthead-header">
        <h1>Instructor Emailing Dashboard</h1>
      </div>
    </div>
    <!-- Masthead Tools -->
    <div class="page-tools col-5">
      <app-masthead-tools></app-masthead-tools>
    </div>
  </div>

  <div class="row">
    <div class="col-8">
      This is where you can send request and reminder emails to relevant course contacts for test definitions.
      You can go to the Manage Course Contacts page to add, edit, or remove contacts as well.
    </div>
  </div>

  <div class="row">
    <div class="col-5">
      <div class="mt margin-top-4-rem">
        <sis-button visualType="primary" size="medium" (clicked)="goToManageCourseContacts()">
          Manage Course Contacts
        </sis-button>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="page-header col-8">
      <div class="margin-top-4-rem">
        <h1>Search for Test Definitions to Send Emails</h1>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-8">
      Below, you can view and search for test definitions that occur on specific days and filter further. You can also
      send request or reminder emails to relevant course contacts according to test definition and send emails in bulk
      for selected test definitions.
    </div>
  </div>

  <!-- Search by Assessment Date -->
  <div>
    <div class="row">
      <div class="col-2 margin-top-2-rem">
        <div><strong>Search by Assessment Date</strong></div>
      </div>
    </div>

    <div class="row ">
      <div class="col-2">
        <app-datepicker [formControl]="searchByAssessmentDateCtrl"></app-datepicker>
        <app-validation-message [control]="searchByAssessmentDateCtrl"></app-validation-message>
      </div>
    </div>
  </div>

  <div class="row margin-top-2-rem">
    <div class="col-12">
      <span>
        <sis-button visualType="primary" size="medium" (clicked)="searchByAssessmentDate()">Search</sis-button>
      </span>
      <span class="margin-left-2rem">
        <sis-button visualType="secondary" size="medium" (clicked)="resetSearch()">Reset Search</sis-button>
      </span>
    </div>
  </div>


  <div class="margin-top-40-px">
    <h2>Test Definitions ({{testDefinitions?.testDefinitions?.length || 0}})</h2>
  </div>

  <div>
    <app-emailing-filter [dataSource]="dataSource" [selection]="selection"></app-emailing-filter>
  </div>

  <div *ngIf="searchByAssessmentDateCtrl.value >= this.todayDate" class="row margin-top-2-rem margin-right-4rem">
    <div class="col-9">
    </div>
    <div class="col-3 float-end text-end">
      <ng-template #dropdownBodyTemplate>
        <button (click)="bulkEmailReminder(EmailReminder.INITIAL_REQUEST)">
          {{Utils.emailReminderMap.get(EmailReminder.INITIAL_REQUEST)}}
        </button>
        <button (click)="bulkEmailReminder(EmailReminder.THREE_DAY_REMINDER)">
          {{Utils.emailReminderMap.get(EmailReminder.THREE_DAY_REMINDER)}}
        </button>
        <button (click)="bulkEmailReminder(EmailReminder.ONE_DAY_REMINDER)">
          {{Utils.emailReminderMap.get(EmailReminder.ONE_DAY_REMINDER)}}
        </button>
      </ng-template>
      <div *ngIf="selection.selected?.length">
        <sis-button visualType="primary" size="medium" [dropdownBodyTemplate]="dropdownBodyTemplate">
          Send {{selection.selected?.length}} Emails in Bulk
        </sis-button>
      </div>
      <div *ngIf="!selection.selected?.length">
        <sis-button visualType="secondary" size="medium"
                    [popoverBody]="'To send emails in bulk, please select test definitions in the table below using the checkboxes in the left-most column of the table.'">
          Send Emails in Bulk
        </sis-button>
      </div>
    </div>
  </div>

  <div class="margin-top-2-rem" *ngIf="searchByAssessmentDateCtrl.value < this.todayDate">
    <sis-notice type="information">
      You cannot send emails for test definitions in the past.
    </sis-notice>
  </div>

  <div class="row margin-top-2-rem margin-right-4rem">
    <div class="col-12">

      <table mat-table [dataSource]="dataSource" table-container matSort class="table">

        <!-- Select All -->
        <ng-container matColumnDef="selectAll">
          <th mat-header-cell *matHeaderCellDef class="checkbox-align">
<!--            Select All ({{selection.selected?.length}})-->
            <br/>
<!--            Commented out for now. Select All will check all checkboxes on all pages...-->
<!--            <mat-checkbox (change)="$event ? toggleAllRows() : null"-->
<!--                          [checked]="selection.hasValue() && isAllSelected()"-->
<!--                          [indeterminate]="selection.hasValue() && !isAllSelected()"-->
<!--                          [aria-label]="checkboxLabel()">-->
<!--            </mat-checkbox>-->
          </th>
          <td mat-cell *matCellDef="let element" class="checkbox-align">
            <mat-checkbox (click)="$event.stopPropagation()"
                          (change)="$event ? selection.toggle(element) : null"
                          [checked]="selection.isSelected(element)"
                          [aria-label]="checkboxLabel(element)">
            </mat-checkbox>
          </td>
        </ng-container>

        <!-- Current Session -->
        <ng-container matColumnDef="courseSection">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Course, Section </th>
          <td mat-cell *matCellDef="let element">
            {{element.academicActivityCode}}
            {{element.sectionCode}}
            {{element.teachMethod}}{{element.sectionNr}}
          </td>
        </ng-container>

        <!-- Type -->
        <ng-container matColumnDef="type">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Type </th>
          <td mat-cell *matCellDef="let element">{{element.testSubType}} {{element.testType}}</td>
        </ng-container>

        <!-- Test Definition ID -->
        <ng-container matColumnDef="testDefinitionId">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> ATS Test Definition ID </th>
          <td mat-cell *matCellDef="let element">{{element.testDefinitionId}}</td>
        </ng-container>

        <!-- Start Time -->
        <ng-container matColumnDef="startTime">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Assessment Start Time </th>
          <td mat-cell *matCellDef="let element">{{element.classStartTime | convertFrom24To12Format}}</td>
        </ng-container>

        <!-- Number of Bookings -->
        <ng-container matColumnDef="numberOfBookings">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Number of Bookings </th>
          <td mat-cell *matCellDef="let element">{{element.numberOfApprovedBookings}}</td>
        </ng-container>

        <!-- CIS Submission Status -->
        <ng-container matColumnDef="cisSubmissionStatus">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> CIS Submission Status </th>
          <td mat-cell *matCellDef="let element">{{Utils.cisSubmissionStatusMap.get(element.cisSubmissionStep)}}</td>
        </ng-container>

        <!-- Instructor Email Status -->
        <ng-container matColumnDef="instructorEmailStatus">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Last Email Sent </th>
          <td mat-cell *matCellDef="let element">
            <!-- - email type: initial request, 3-day reminder, 1-day reminder -->
            {{Utils.emailReminderMap.get(element.emailType)}} <br/>
            <!-- - email sent date -->
            {{!element.emailSentDate ? '' : '('}}{{!element.emailSentDate ?
                    '' : element.emailSentDate | date:'yyyy-MM-dd'}}{{!element.emailSentDate ? '' : ')'}} <br/>
            <!-- - view activity log -->
            <button type="button" class="btn btn-link" (click)="viewActivityLog(element)">
              {{element.emailType === EmailReminder.NONE ? '' : 'View Activity'}}
            </button>
          </td>
        </ng-container>

        <!-- Edit Email buttons -->
        <ng-container matColumnDef=" ">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let element">
            <ng-template #dropdownBodyTemplate>
              <button (click)="emailReminder(EmailReminder.INITIAL_REQUEST, element.testDefinitionId)">
                {{Utils.emailReminderMap.get(EmailReminder.INITIAL_REQUEST)}}
              </button>
              <button (click)="emailReminder(EmailReminder.THREE_DAY_REMINDER, element.testDefinitionId)">
                {{Utils.emailReminderMap.get(EmailReminder.THREE_DAY_REMINDER)}}
              </button>
              <button (click)="emailReminder(EmailReminder.ONE_DAY_REMINDER, element.testDefinitionId)">
                {{Utils.emailReminderMap.get(EmailReminder.ONE_DAY_REMINDER)}}
              </button>
            </ng-template>
            <sis-button visualType="primary" size="small" [dropdownBodyTemplate]="dropdownBodyTemplate">
              Send Email
            </sis-button>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayColumns;"></tr>
      </table>

      <div class="">
        <mat-paginator [length]="3" #paginator
          [pageSize]="20"
          [pageSizeOptions]="[20, 40]" showFirstLastButtons="true">
        </mat-paginator>
      </div>

    </div>
  </div>

</div>


