import {Component, Input} from '@angular/core';
import {NavigationExtras, Router} from "@angular/router";
import {AccommodationInstructions} from "../../model/types/accommodation-instructions";
import {LogService} from "../../common/services/log-service.service";

@Component({
  selector: 'app-act-log-masthead',
  templateUrl: './act-log-masthead.component.html',
  styleUrls: ['./act-log-masthead.component.scss']
})
export class ActLogMastheadComponent {

  @Input()
  cisSubmission: AccommodationInstructions;
  @Input()
  loggedInUser: string;

  constructor(private router: Router, private logger: LogService) {
  }


  goCisSubmissionActivityLog() {

    let navigationExtras: NavigationExtras = {
      state: {
        cisSubmission: this.cisSubmission,
        loggedInUser: this.loggedInUser
      }
    };

    this.router.navigate([`/cis-submissions/${this.cisSubmission.atsTestDefinitionId}/activity-log`], navigationExtras)
      .then(nav => {}, err => {this.logger.error("Navigation failed with error: " + err, `${this.constructor.name}`)});
  }

}
