import {Component, OnInit} from '@angular/core';
import {CisSubmissionsUtils} from "../cis-submissions-utils";
import {NavigationExtras, Router} from "@angular/router";
import {AccommodationInstructions} from "../../model/types/accommodation-instructions";
import {ReviewStatusCode} from "../review-status-code.enum";
import {LogService} from "../../common/services/log-service.service";

@Component({
  selector: 'app-activity-log',
  templateUrl: './activity-log.component.html',
  styleUrls: ['./activity-log.component.scss']
})
export class ActivityLogComponent implements OnInit {

  cisSubmission: AccommodationInstructions;
  loggedInUser: string;

  constructor(private router: Router,
              private logger: LogService) {

    // Get the NavigationExtras (set in the component where the navigation started).
    const navigation = this.router.getCurrentNavigation();
    const state = navigation.extras.state as {
      cisSubmission: AccommodationInstructions,
    };

    this.cisSubmission = state?.cisSubmission;
  }

  ngOnInit(): void {}

  goBackToSubmissionDetails() {

    let navigationExtras: NavigationExtras = {
      state: {
        cisSubmission: this.cisSubmission,
        loggedInUser: this.loggedInUser
      }
    };

    this.router.navigate([`/cis-submissions/${this.cisSubmission.atsTestDefinitionId}`], navigationExtras)
      .then(
        nav => {},
          err => {this.logger.error("Navigation failed with error: " + err, `${this.constructor.name}`)}
      );
  }


  protected readonly ReviewStatusCode = ReviewStatusCode;
  protected readonly Utils = CisSubmissionsUtils;

}
