
<div class="activity-log">

  <!-- Masthead -->
  <div class="row">
    <!-- Masthead -->
    <div class="row">
      <!-- Masthead Header -->
      <div class="page-header col-7">
        <div class="masthead-header">
          <h1>Activity Log</h1>
        </div>
      </div>
      <!-- Masthead Tools -->
      <div class="page-tools col-5">
        <app-masthead-tools></app-masthead-tools>
      </div>
    </div>
  </div>

  <div>
    <!-- Display CIS submission general info -->
    <app-cis-sub-gen-info [cisSubmission]="cisSubmission"></app-cis-sub-gen-info>
  </div>
  <br/>

  <div class="row">
    <div class="col-9">
      <div *ngIf="cisSubmission._activityLog">
        <table class="table">
          <thead>
            <tr>
              <th scope="col">Date & Time</th>
              <th scope="col">User</th>
              <th scope="col">Description</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let log of cisSubmission._activityLog">
              <td>{{log.date | date: 'YYYY-MM-dd, HH:mm a'}}</td>
              <td>{{log.utorId}}</td>
              <td>{{log.description}}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>

  <!-- Back to CIS Submissions Dashboard -->
  <div class="margin-top-4-rem">
    <button class="btn btn-link" (click)="goBackToSubmissionDetails()">Back to Review CIS Submission Details</button>
  </div>

</div>
