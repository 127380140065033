

<table mat-table [dataSource]="dataSource" matSort class="table table-container table-responsive">

  <!-- Note -->
  <ng-container matColumnDef="note">
    <th mat-header-cell *matHeaderCellDef> Note </th>
    <td mat-cell *matCellDef="let element">
      {{element.note}}
    </td>
  </ng-container>

  <!-- Note -->
  <ng-container matColumnDef="userAndTimestamp">
    <th mat-header-cell *matHeaderCellDef mat-sort-header> User & Timestamp </th>
    <td mat-cell *matCellDef="let element">
      {{element.utorId}} at {{element.date | date: 'yyyy-MM-dd, h:mm a'}}
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayColumns;"></tr>
</table>
