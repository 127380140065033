import {BrowserModule} from '@angular/platform-browser';
import {CUSTOM_ELEMENTS_SCHEMA, NgModule, NO_ERRORS_SCHEMA} from '@angular/core';
import {AppComponent} from './app.component';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';

import {SisCoreModule} from "@easi-sis/core";

import {AppHeaderComponent} from './app-header/app-header.component';
import {AppFooterComponent} from './app-footer/app-footer.component';
import {AppContentComponent} from './app-content/app-content.component';
import {DesignHttpRequestInterceptor} from './design/design.http.service';
import {HttpRequestInterceptor} from './interceptor';

import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {MastheadToolsComponent} from './common/components/masthead-tools/masthead-tools.component';
import {MastheadHeaderComponent} from './booking/components/masthead-header/masthead-header.component';
import {
  NgbActiveModal,
  NgbDatepickerModule,
  NgbDropdownModule,
  NgbModule,
  NgbNavModule,
  NgbTimepickerModule
} from '@ng-bootstrap/ng-bootstrap';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {StudentBookingComponent} from './booking/components/student-booking/student-booking.component';
import {RouterModule, Routes} from '@angular/router';
import {CheckboxDropDownComponent} from './checkbox-drop-down/checkbox-drop-down.component';
import {AppHomepageComponent} from './app-homepage/app-homepage.component';
import {CourseContactsCardComponent} from './booking/components/course-contacts-card/course-contacts-card.component';
import {ScheduleCardComponent} from './booking/components/schedule-card/schedule-card.component';
import {
  ActivityLogAndNotesTabComponent
} from './booking/components/activity-log-and-notes-tab/activity-log-and-notes-tab.component';
import {TestDetailsTabComponent} from './booking/components/test-details-tab/test-details-tab.component';
import {AppBreadcrumbComponent} from './app-breadcrumb/app-breadcrumb.component';
import {AppStudentProfileComponent} from './app-student-profile/app-student-profile.component';
import {
  AppStudentInformationComponent
} from './app-student-profile/app-student-information/app-student-information.component';
import {AppStudentAdvisorComponent} from './app-student-profile/app-student-advisor/app-student-advisor.component';
// tslint:disable-next-line:max-line-length
import {
  AppStudentEmergencyContactComponent
} from './app-student-profile/app-student-emergency-contact/app-student-emergency-contact.component';
// tslint:disable-next-line:max-line-length
import {
  AppStudentAccommodationsTabsComponent
} from './app-student-profile/app-student-accommodations-tabs/app-student-accommodations-tabs.component';
import {DATA_SOURCE} from './model/data-sources/data-source';
import {LogLevel, LogService} from './common/services/log-service.service';
import {ModelModule} from './model/model.module';
import {UnsavedGuard} from './common/guards/unsaved.guard';
import {BasicModalComponent} from './common/components/basic-modal/basic-modal.component';
import {CommonModule, DatePipe} from '@angular/common';
import {RestDataSourceService} from './model/data-sources/rest-data-source.service';
import {StudentBookingResolverService} from './booking/guards/student-booking-resolver.service';
import {PrintTestMaterialComponent} from './print-test-material/print-test-material.component';
import {DateFilter} from './common/filters/dateFilter';
import {SpinnerComponent} from './common/components/spinner/spinner.component';
import {SpinnerService} from './common/services/spinner.service';
import {ErrorHandlerInterceptor} from './common/interceptors/error-handler.interceptor';
import {StudentProfileResolverService} from "./app-student-profile/guards/student-profile-resolver.service";
import {OAuthModule, OAuthStorage} from 'angular-oauth2-oidc';
import {ManualBookingCreationComponent} from './manual-booking-creation/manual-booking-creation.component';
import {TimeFormat} from './common/filters/TimeFormat';
import {PanelSelectorComponent} from './common/components/panel-selector/panel-selector.component';
// tslint:disable-next-line:max-line-length
import {
  AppAccommodationsSelectionWidgetComponent
} from './app-accommodations-selection-widget/app-accommodations-selection-widget.component';
import {ManualBookingResolverService} from "./manual-booking-creation/guards/manual-booking-resolver-service";
import {StudentSearchComponent} from './manual-booking/student-search/student-search.component';
import {CourseSearchComponent} from './manual-booking/course-search/course-search.component';
// @ts-ignore
import {
  StudentAccommodationResolverService
} from './app-student-creation/guards/student-accommodation-resolver.service';
import {SettingsComponent} from './settings/settings.component';
import {SortBy} from "./common/filters/sortBy";
import {AssignDesignateComponent} from './designates/assign-designate/assign-designate.component';
import {DesignateDashboardComponent} from './designates/designate-dashboard/designate-dashboard.component';
import {SeatSelectorComponent} from "./design/seat-selector/seat-selector.component";
import {AsyncCourseSearchComponent} from './designates/async-course-search/async-course-search.component';
import {RemoveDesigModalComponent} from './designates/remove-desig-modal/remove-desig-modal.component';
import {DesigBindingComponent} from './designates/desig-binding/desig-binding.component';
import {RfDatepickerComponent} from './common/components/rf-datepicker/rf-datepicker.component';
import {ValidationMessageComponent} from './validator/validation-message/validation-message.component';
import {ColumnsToSearchComponent} from './app-homepage/columns-to-search/columns-to-search.component';
import {OrgDesigateTableComponent} from './designates/org-desigate-table/org-desigate-table.component';
import {CourseDesigateTableComponent} from './designates/course-desigate-table/course-desigate-table.component';
import {RfTimepickerComponent} from './common/components/rf-timepicker/rf-timepicker.component';
import {Environment} from "../environment";
import {StudentSearchModalComponent} from "./app-student-profile/student-search-modal/student-search-modal.component";
import {StudBookingTabComponent} from './booking/components/stud-booking-tab/stud-booking-tab.component';
import {AccomComponent} from './booking/components/accom/accom.component';
import {RfDurationpickerComponent} from './common/components/rf-durationpicker/rf-durationpicker.component';
import {ConflictComponent} from './booking/components/conflict/conflict.component';
import {
  AppTestDefinitionDashboardComponent
} from './app-test-definition-dashboard/app-test-definition-dashboard.component';
import {DragDropModule} from "@angular/cdk/drag-drop";
import {CdkTableModule} from "@angular/cdk/table";
import {MatLegacyTableModule as MatTableModule} from "@angular/material/legacy-table";
import {MatLegacyPaginatorModule as MatPaginatorModule} from "@angular/material/legacy-paginator";
import {MatSortModule} from "@angular/material/sort";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {MatLegacyCardModule as MatCardModule} from "@angular/material/legacy-card";
import {MatLegacyFormFieldModule as MatFormFieldModule} from "@angular/material/legacy-form-field";
import {MatLegacyInputModule as MatInputModule} from "@angular/material/legacy-input";
import {MatLegacyTabsModule as MatTabsModule} from "@angular/material/legacy-tabs";
import {AppTestDefinitionComponent} from './app-test-definition/app-test-definition.component';
import {TestDefinitionResolverService} from "./app-test-definition/guards/test-definition-resolver.service";
import {
  ProvisionStudentModalComponent
} from './booking/components/provision-student-modal/provision-student-modal.component';
import {BookingNotesTabComponent} from './booking/components/booking-notes-tab/booking-notes-tab.component';
import {VersionComponent} from './version/version.component';
import {UploadFilesComponent} from './upload-files/upload-files.component';
import {
  ClassWritingScheduleCardComponent
} from './test-def/class-writing-schedule-card/class-writing-schedule-card.component';
import {TestDefDetailsCardComponent} from './test-def/test-def-details-card/test-def-details-card.component';
import {EditTestDefComponent} from './test-def/edit-test-def/edit-test-def.component';
import {AddTestDefComponent} from './test-def/add-test-def/add-test-def.component';
import {AssessmentTypesResolver} from "./common/resolvers/assessment-types.resolver";
import {HttpCourseSearchComponent} from './test-def/http-course-search/http-course-search.component';
import {AuthTokenInterceptor} from "./common/interceptors/auth-token.interceptor";
import {PeriodSelectorComponent} from './common/components/period-selector/period-selector.component';
import {PhonebookApiImpl} from "./common/services/phonebook-api-impl.service";
import {PHONEBOOK_API} from "./common/services/phonebook-api";
import {STORAGE_SERVICE} from "./common/services/storage.service";
import {StorageImpl} from "./common/services/storage-impl.service";
import {UnsavedChangesComponent} from './common/components/unsaved-changes/unsaved-changes.component';
import {MatLegacyCheckboxModule as MatCheckboxModule} from "@angular/material/legacy-checkbox";
import {
  SelectableBookingsTableComponent
} from './test-def/selectable-bookings-table/selectable-bookings-table.component';
import {ExportTestDefCardComponent} from './test-def/export-test-def-card/export-test-def-card.component';
import {ToastNoAnimationModule} from "ngx-toastr";
import {ValidationMsgComponent} from './validator/validation-msg/validation-msg.component';
import {DeleteTestDefComponent} from './test-def/delete-test-def/delete-test-def.component';
import {DeleteTestDefModalComponent} from './test-def/delete-test-def-modal/delete-test-def-modal.component';
import {CancelBookingModalComponent} from './booking/components/cancel-booking-modal/cancel-booking-modal.component';
import {EmailDashboardComponent} from './instructor-emailing/email-dashboard/email-dashboard.component';
import {ContactDetailComponent} from './instructor-emailing/contact-detail/contact-detail.component';
import {CourseContactsComponent} from './instructor-emailing/course-contacts/course-contacts.component';
import {DatepickerComponent} from './common/components/datepicker/datepicker.component';
import {
  CoursePrefixTypeaheadComponent
} from './instructor-emailing/course-prefix-typeahead/course-prefix-typeahead.component';
import {
  CourseCodeTypeaheadComponent
} from './instructor-emailing/course-code-typeahead/course-code-typeahead.component';
import {
  CourseMeetingSecTypeaheadComponent
} from './instructor-emailing/course-meeting-sec-typeahead/course-meeting-sec-typeahead.component';
import {
  DeleteCourseContactsModalComponent
} from './instructor-emailing/delete-course-contacts-modal/delete-course-contacts-modal.component';
import {
  CourseContactsDashboardComponent
} from './instructor-emailing/course-contacts-dashboard/course-contacts-dashboard.component';
import {
  CourseContactsFilterComponent
} from './instructor-emailing/course-contacts-filter/course-contacts-filter.component';
import {EmailingFilterComponent} from './instructor-emailing/emailing-filter/emailing-filter.component';
import {SendEmailComponent} from './instructor-emailing/send-email/send-email.component';
import {SendBulkEmailComponent} from './instructor-emailing/send-bulk-email/send-bulk-email.component';
import {TestDefInfoComponent} from './instructor-emailing/test-def-info/test-def-info.component';
import { SendEmailTableComponent } from './instructor-emailing/send-email-table/send-email-table.component';
import { SendEmailModalComponent } from './instructor-emailing/send-email-modal/send-email-modal.component';
import { TypeOfReminderEmailComponent } from './instructor-emailing/type-of-reminder-email/type-of-reminder-email.component';
import { EmailLogModalComponent } from './instructor-emailing/email-log-modal/email-log-modal.component';
import { BulkEmailSummaryComponent } from './instructor-emailing/bulk-email-summary/bulk-email-summary.component';
import { CisSubmissionsDashboardComponent } from './cis-submissions/cis-submissions-dashboard/cis-submissions-dashboard.component';
import { CisSubmissionsFilterComponent } from './cis-submissions/cis-submissions-filter/cis-submissions-filter.component';
import { CisSubmissionDetailsComponent } from './cis-submissions/cis-submission-details/cis-submission-details.component';
import { ViewStatisticsModalComponent } from './cis-submissions/view-statistics-modal/view-statistics-modal.component';
import { ReviewNotesTableComponent } from './cis-submissions/review-notes-table/review-notes-table.component';
import { ActivityLogComponent } from './cis-submissions/activity-log/activity-log.component';
import { CisSubGenInfoComponent } from './cis-submissions/cis-sub-gen-info/cis-sub-gen-info.component';
import { ActLogMastheadComponent } from './cis-submissions/act-log-masthead/act-log-masthead.component';
import {
  StudentSearchMastheadComponent
} from "./common/components/student-search-masthead/student-search-masthead.component";
import { ProvisionStudentMastheadComponent } from './common/components/provision-student-masthead/provision-student-masthead.component';

export function storageFactory(): OAuthStorage {
  return localStorage;
}

const routes: Routes = [
  { path: 'designate-dashboard', component: DesignateDashboardComponent},
  { path: 'assign-designate', component: AssignDesignateComponent},

  { path: 'instructor-emailing', component: EmailDashboardComponent},
  { path: 'instructor-emailing/manage-course-contacts', component: CourseContactsDashboardComponent},
  { path: 'instructor-emailing/email-contacts', component: CourseContactsComponent},
  { path: 'instructor-emailing/send-email', component: SendEmailComponent},
  { path: 'instructor-emailing/send-bulk-email', component: SendBulkEmailComponent},
  { path: 'instructor-emailing/bulk-email-summary', component: BulkEmailSummaryComponent},

  { path: 'cis-submissions', component: CisSubmissionsDashboardComponent },
  { path: 'cis-submissions/:id', component: CisSubmissionDetailsComponent },
  { path: 'cis-submissions/:testDefId/activity-log', component: ActivityLogComponent },

  // The ':id' is the ATS Student Id
  { path: 'student-profile/:id',
    component: AppStudentProfileComponent,
    resolve: {model: StudentProfileResolverService} },

  { path: 'student-booking/:id',
    component: StudentBookingComponent,
    canDeactivate: [UnsavedGuard],
    resolve: { model: StudentBookingResolverService } },
  { path: '',
    component: AppHomepageComponent,
  },
  { path: 'test-definitions',
    component: AppTestDefinitionDashboardComponent,
  },
  { path: 'test-definition/:id',
    component: AppTestDefinitionComponent,
    resolve: { model: TestDefinitionResolverService } },
  { path: 'add-test-def',
    component: AddTestDefComponent,
    resolve: { assessmentTypes: AssessmentTypesResolver} },
  { path: 'upload-test-def', component: UploadFilesComponent},
  { path: 'edit-test-def/:id',
    component: EditTestDefComponent,
    canDeactivate: [UnsavedGuard] },
  { path: 'index.html', redirectTo: ''},
  { path: 'settings', component: SettingsComponent},
  { path: 'manual-booking', component: ManualBookingCreationComponent,
     resolve: {Object: ManualBookingResolverService}},
  { path: 'version', component: VersionComponent}
];



@NgModule({
  declarations: [
    AppComponent,
    AppHeaderComponent,
    AppFooterComponent,
    AppContentComponent,
    CheckboxDropDownComponent,
    AppHomepageComponent,
    MastheadToolsComponent,
    MastheadHeaderComponent,
    PrintTestMaterialComponent,
    DateFilter,
    TimeFormat,
    StudentBookingComponent,
    CourseContactsCardComponent,
    ScheduleCardComponent,
    ActivityLogAndNotesTabComponent,
    TestDetailsTabComponent,
    AppBreadcrumbComponent,
    AppStudentProfileComponent,
    AppStudentInformationComponent,
    AppStudentAdvisorComponent,
    AppStudentEmergencyContactComponent,
    AppStudentAccommodationsTabsComponent,
    BasicModalComponent,
    SpinnerComponent,
    ManualBookingCreationComponent,
    PanelSelectorComponent,
    ManualBookingCreationComponent,
    AppAccommodationsSelectionWidgetComponent,
    StudentSearchComponent,
    CourseSearchComponent,
    SettingsComponent,
    SortBy,
    AssignDesignateComponent,
    DesignateDashboardComponent,
    SeatSelectorComponent,
    AsyncCourseSearchComponent,
    RemoveDesigModalComponent,
    DesigBindingComponent,
    RfDatepickerComponent,
    ValidationMessageComponent,
    ColumnsToSearchComponent,
    OrgDesigateTableComponent,
    CourseDesigateTableComponent,
    RfTimepickerComponent,
    StudentSearchModalComponent,
    StudBookingTabComponent,
    AccomComponent,
    RfDurationpickerComponent,
    ConflictComponent,
    AppTestDefinitionDashboardComponent,
    AppTestDefinitionComponent,
    ProvisionStudentModalComponent,
    BookingNotesTabComponent,
    VersionComponent,
    UploadFilesComponent,
    ClassWritingScheduleCardComponent,
    TestDefDetailsCardComponent,
    EditTestDefComponent,
    AddTestDefComponent,
    HttpCourseSearchComponent,
    PeriodSelectorComponent,
    UnsavedChangesComponent,
    SelectableBookingsTableComponent,
    ExportTestDefCardComponent,
    ValidationMsgComponent,
    DeleteTestDefComponent,
    DeleteTestDefModalComponent,
    CancelBookingModalComponent,
    EmailDashboardComponent,
    ContactDetailComponent,
    CourseContactsComponent,
    DatepickerComponent,
    CoursePrefixTypeaheadComponent,
    CourseCodeTypeaheadComponent,
    CourseMeetingSecTypeaheadComponent,
    DeleteCourseContactsModalComponent,
    CourseContactsDashboardComponent,
    CourseContactsFilterComponent,
    EmailingFilterComponent,
    SendEmailComponent,
    SendBulkEmailComponent,
    TestDefInfoComponent,
    SendEmailTableComponent,
    SendEmailModalComponent,
    TypeOfReminderEmailComponent,
    EmailLogModalComponent,
    BulkEmailSummaryComponent,
    CisSubmissionsDashboardComponent,
    CisSubmissionsFilterComponent,
    CisSubmissionDetailsComponent,
    ViewStatisticsModalComponent,
    ReviewNotesTableComponent,
    ActivityLogComponent,
    CisSubGenInfoComponent,
    ActLogMastheadComponent,
    StudentSearchMastheadComponent,
    ProvisionStudentMastheadComponent
  ],
    imports: [
        RouterModule.forRoot(routes,
            { enableTracing: true }),
        BrowserModule,
        FontAwesomeModule,
        ReactiveFormsModule,
        NgbNavModule,
        NgbDatepickerModule,
        NgbTimepickerModule,
        FormsModule,
        OAuthModule.forRoot(),
        ToastNoAnimationModule.forRoot({
            positionClass: 'toast-top-full-width',
            timeOut: 5000,
            preventDuplicates: true,
            closeButton: true,
        }),
        ModelModule,
        NgbDropdownModule,
        HttpClientModule,
        NgbModule,
        CommonModule,
        DragDropModule,
        CdkTableModule,
        MatTableModule,
        MatPaginatorModule,
        MatSortModule,
        BrowserAnimationsModule,
        MatCardModule,
        MatFormFieldModule,
        MatInputModule,
        MatTabsModule,
        SisCoreModule,
        MatCheckboxModule
    ],
  schemas: [
    NO_ERRORS_SCHEMA,
    CUSTOM_ELEMENTS_SCHEMA
  ],
  exports: [
    CheckboxDropDownComponent,
    CommonModule,
    DragDropModule,
    CdkTableModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    BrowserAnimationsModule,
    MatCardModule,
    MatFormFieldModule,
    MatInputModule,
    MatTabsModule,
    SisCoreModule
  ],
  providers: [
    { provide: OAuthStorage, useFactory: storageFactory},
    { provide: HTTP_INTERCEPTORS, useClass: ErrorHandlerInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: AuthTokenInterceptor, multi: true },
    {
      provide: HTTP_INTERCEPTORS,
      // The shake tree will remove the dead code. No need to remove the unused DesignHttpRequestInterceptor in prod
      useClass: Environment.mode === 'design' ? DesignHttpRequestInterceptor : HttpRequestInterceptor,
      multi: true
    },
    { provide: LogService, useFactory: () => {
        const logger = new LogService();
        /** For PROD environment use ERROR log level. For all the others use DEBUG log level. */
        logger.minimumLevel = Environment.mode === 'prod' ? LogLevel.ERROR : LogLevel.DEBUG;
        return logger;
      }
    },
    { provide: DATA_SOURCE, useClass: RestDataSourceService },
    {provide: PHONEBOOK_API, useClass: PhonebookApiImpl},
    {provide: STORAGE_SERVICE, useClass: StorageImpl},
    DatePipe, SpinnerService, NgbActiveModal,
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
