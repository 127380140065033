import {Component, OnInit} from '@angular/core';
import {Router} from "@angular/router";
import {AccommodationInstructions} from "../../model/types/accommodation-instructions";
import {LogService} from "../../common/services/log-service.service";
import {ModelService} from "../../model/model.service";
import {ReviewStatusCode} from "../review-status-code.enum";
import {CisSubmissionsUtils} from "../cis-submissions-utils";
import {AidTypes} from "../../model/types/aid-types";
import {ScantronVersions} from "../../model/types/scantron-versions";
import {AudioVisualComps} from "../../model/types/audio-visual-comps";
import {OnlineAssessmentTypes} from "../../model/types/OnlineAssessmentTypes";
import {FormControl} from "@angular/forms";
import {AtsNote} from "../ats-note";
import {EMPTY, mergeMap, Observable} from "rxjs";
import {ToastService} from "@easi-sis/core";

@Component({
  selector: 'app-cis-submission-details',
  templateUrl: './cis-submission-details.component.html',
  styleUrls: ['./cis-submission-details.component.scss']
})
export class CisSubmissionDetailsComponent implements OnInit {

  cisSubmission: AccommodationInstructions;
  loggedInUser: string;

  atsCisSubmissionNotesCtrl: FormControl = new FormControl('');

  aidMap: Map<number, AidTypes> = new Map();
  scantronMap: Map<number, ScantronVersions> = new Map();
  audiovisualMap: Map<number, AudioVisualComps> = new Map();
  onlineAssessmentTypeMap: Map<number, OnlineAssessmentTypes> = new Map();

  constructor(private router: Router,
              private toastService: ToastService,
              private model: ModelService,
              private logger: LogService) {

    // Get the NavigationExtras (set in the component where the navigation started).
    const navigation = this.router.getCurrentNavigation();
    const state = navigation.extras.state as {
      cisSubmission: AccommodationInstructions,
      loggedInUser: string
    };

    this.cisSubmission = state?.cisSubmission;
    this.loggedInUser = state?.loggedInUser;

    // this.logger.debug(`=> CIS submission: ${JSON.stringify(this.cisSubmission)}`, `${this.constructor.name}`);
  }

  ngOnInit(): void {
    this.model.getAccommodationChoices().subscribe(res => {

        this._createAidMap(res.aidTypes);
        this._createScantronMap(res.scantronVersions);
        this._createAudiovisualMap(res.audiovisualComps);
        this._createOnlineAssessmentTypeMap(res.onlineAssessmentTypes);
    })
  }

  _createAidMap(aids: AidTypes[]): void {
    if (!Array.isArray(aids)) { return; }

    // populate the map
    this.aidMap.clear();
    aids.forEach(aid => this.aidMap.set(aid.aidTypeId, aid));
  }

  _createScantronMap(scantrons: ScantronVersions[]): void {
    if (!Array.isArray(scantrons)) { return; }

    // populate the map
    this.scantronMap.clear();
    scantrons.forEach(sc => this.scantronMap.set(sc.scantronVersionId, sc));
  }

  _createAudiovisualMap(audiovisuals: AudioVisualComps[]) {
    if (!Array.isArray(audiovisuals)) { return; }

    // populate the map
    this.audiovisualMap.clear();
    audiovisuals.forEach(av => this.audiovisualMap.set(av.audiovisualCompId, av));
  }

  _createOnlineAssessmentTypeMap(onlineAssessmentTypes: OnlineAssessmentTypes[]) {
    if (!Array.isArray(onlineAssessmentTypes)) { return; }

    // populate the map
    this.onlineAssessmentTypeMap.clear();
    onlineAssessmentTypes.forEach(oat => this.onlineAssessmentTypeMap.set(oat.onlineAssessmentTypeId, oat));
  }

  setReviewStatus(atsTestDefId: number, reviewStatusCode: ReviewStatusCode) {

    this.model.setReviewStatus(
      { atsTestDefId: atsTestDefId,
        prevReviewStatusCode: ReviewStatusCode[this.cisSubmission._reviewStatus], //converts string to enum
        reviewStatusCode: reviewStatusCode }
    ).subscribe(() => {

        // Success toast message
        const msg = ` You marked the CIS Submission for ${this.Utils.formatCourse(this.cisSubmission)}
                    (Test Definition ID: ${atsTestDefId}) as ${this.Utils.reviewStatusCodeMap.get(reviewStatusCode)}.`;
        this.toastService.show({type: 'success', action: msg});

        this.router.navigate(['/cis-submissions']);
    });
  }

  /** Adds ATS  note, followed by retrieving all notes. */
  addAtsNote(): void {
    const atsTestDefinitionId = this.cisSubmission.atsTestDefinitionId;

    this._addAtsNote().pipe(
      mergeMap(()=> this._getAtsNotes(atsTestDefinitionId)))
      .subscribe((val: AtsNote[]) => {
        this.cisSubmission._atsNotes = val;
        this.atsCisSubmissionNotesCtrl.setValue('');

        // Success toast message
        const msg = `You added a note for the CIS Submission for ${this.Utils.formatCourse(this.cisSubmission)}
                                                                      (Test Definition ID: ${atsTestDefinitionId}).`;
        this.toastService.show({type: 'success', action: msg});
      });
  }


  /* Adds notes to CIS submission. We ignore the result. */
  _addAtsNote(): Observable<any> {
    const note = this.atsCisSubmissionNotesCtrl.value;

    // If no note was entered, return.
    if (!note || note.trim().length === 0) {
      return EMPTY;
    }

    const atsTestDefinitionId = this.cisSubmission.atsTestDefinitionId;
    const atsNote = new AtsNote(atsTestDefinitionId, note);

    return this.model.addAtsNoteForCisSubmission(atsNote);
  }

  /* Retrieves CIS submission notes */
  _getAtsNotes(atsTestDefId: number): Observable<AtsNote[]> {
    return this.model.getAtsNotesForCisSubmission(atsTestDefId);
  }

  protected readonly JSON = JSON;
  protected readonly ReviewStatusCode = ReviewStatusCode;
  protected readonly Utils = CisSubmissionsUtils;
}
