export class CommonUtils {

  //** Check if all user entered tokens, in filter field, match the data field. */
  public static checkMatch(data: string, filter: string): boolean {
    if (!filter) return  true;

    const wordsArray: string[] = filter.split(' ');
    const textMatch: boolean = wordsArray.every((value) => {
      const matchExp: RegExp = new RegExp(value, 'i');
      return  matchExp.test(data);
    });

    return textMatch;
  }

}
