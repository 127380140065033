import {Injectable} from '@angular/core';
import {DataSource} from './data-source';
import {AsyncSubject, Observable, of} from 'rxjs';
import {StudentInfo} from '../types/studentInfo';
import {StudentBooking} from '../types/student-booking';
import {ActivityLog} from '../types/activityLog';
import {Person} from '../types/person';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Bookings} from '../types/bookings';
import {PrintTestMaterial} from '../types/print-test-material';
import {OAuthStorage} from 'angular-oauth2-oidc';
import {AuthenticationService} from '../../common/services/authentication.service';
import {Accommodation} from '../types/accommodation';
import {Course} from "../types/course";
import {StudentAccommodations} from '../types/student-accommodations';
import {ManualBooking} from "../types/manual-booking";
import {SelectedTestDef} from "../types/selected-test-def";
import {AccommodationConflict} from "../types/accommodation-conflict";
import {StudentName} from '../types/student-name';
import {StudentSearch} from '../types/student-search';
import {StaffNotes} from "../types/staff-notes";
import {AccommodationInstructions} from "../types/accommodation-instructions";
import {AccommodationChoices} from "../types/accommodation-choices";
import {EditBookingDetails} from "../types/edit-booking-details";
import {DesignateAssignment} from "../types/designate-assignment";
import {ActivityOfferOrg} from "../types/activity-offer-org";
import {Room} from "../types/room";
import {Designate} from "../types/designate";
import {TestType} from "../types/testType";
import {SpaceOrOnline} from "../types/space-or-online";
import {AccommodationSearch} from "../types/accommodation-search";
import {Environment} from "../../../environment";
import {StudentCourseAccommodations} from "../types/student-course-accommodations";
import {TestDefinitions} from "../types/test-definitions";
import {WholeTestDefinition} from "../types/whole-test-definition";
import {CourseBookings} from "../types/course-bookings";
import {TestDef} from "../types/test-def";
import {FileUploadResp} from "../types/file-upload-resp";
import {AuthService} from "../../common/services/auth.service";
import moment from "moment";
import {DateFormatConstants} from "../../common/types/dateFormatConstants";
import {PeriodCourseSelector} from "../types/periodCourseSelector";
import {SimpleBooking} from "../types/simple-booking";
import {UpdatedBookingStatus} from "../types/updated-booking-status";
import {CourseContacts} from "../types/course-contacts";
import {CourseByLevelOfInstr} from "../types/course-by-level-of-instr";
import {CourseContactsPk} from "../../instructor-emailing/course-contacts-pk";
import {AtsResp} from "../types/ats-resp";
import {InstructorEmailContacts} from "../../instructor-emailing/instructor-email-contacts";
import {SelectedEmailContacts} from "../../instructor-emailing/selected-email-contacts";
import {NotificationLog} from "../../instructor-emailing/notification-log";
import {EmailResp} from "../../instructor-emailing/email-resp";
import {ReviewStatus} from "../../cis-submissions/review-status";
import {CisSubmissions} from "../../cis-submissions/cis-submissions";
import {AtsNote} from "../../cis-submissions/ats-note";
import {Reviewer} from "../../cis-submissions/reviewer";

/**
 * Service: RestDataSourceService
 * Desc:
 *    This service is used to get back-end data by making REST-ful calls.
 *
 *   The use this service change the configuration in model.module.ts module providers from:
 *        { provide: DATA_SOURCE, useClass: RestDataSourceService }
 *   to:
 *        { provide: DATA_SOURCE, useClass: StaticDataSourceService }
 */


// tslint:disable-next-line:one-variable-per-declaration

@Injectable({
  providedIn: 'root'
})
export class RestDataSourceService implements DataSource {

  // private headers: HttpHeaders = new HttpHeaders();

  private allBookingsSubject: AsyncSubject<Bookings>;

  private url = Environment.restUrl;

  constructor(private authenticationService: AuthenticationService,
              private oAuthStorage: OAuthStorage, private http: HttpClient, private authService: AuthService) {

    // this.authenticationService.whenLoggedIn().subscribe(event => {
    //   if (event) {
    //       const idToken = this.oAuthStorage.getItem('id_token');
    //       this.headers = this.headers.set('Authorization', 'Bearer ' + idToken);
    //       if (Environment.isRelease) {
    //         this.headers = this.headers.set('Api-Version', 'release');
    //       }
    //   }
    //
    // });

    // this.authenticationService.isHttpHeadTokenRemoved().subscribe((isTokenRemoved: boolean) => {
    //   if (isTokenRemoved) {
    //     this.headers = this.headers.set('Authorization', "");
    //   }
    // });
  }

  /** Get current week bookings. Will be displayed on the home page when user loggin. */
  getTodayBookings(): Observable<Bookings> {

      this.allBookingsSubject = new AsyncSubject<Bookings>();

      // this.authenticationService.whenLoggedIn().subscribe(x => {
      //   if (x) {
      // const idToken = this.oAuthStorage.getItem('id_token');
      // this.headers = this.headers.set('Authorization', 'Bearer ' + idToken);

      // if (Environment.isRelease) {
      //   this.headers = this.headers.set('Api-Version', 'release');
      // }

      const datesString: string[] = [];
      datesString.push(moment().format(DateFormatConstants.YYYYMMDD));
      datesString.push(moment().format(DateFormatConstants.YYYYMMDD));
      const options = {params: new HttpParams().set('startEndDate', datesString.toString())};

      this.authService.whenLoggedIn().subscribe(userIsLoggedIn => {
        if (userIsLoggedIn) {
          this.http.get<Bookings>(`${this.url}/student/bookings`, options).subscribe(value => {
            // emit the bookings through to listeners on the subject.
            this.allBookingsSubject.next(value);
            this.allBookingsSubject.complete();
          });
        }
      });

      return this.allBookingsSubject;
  }

  getTodayTestDefinitions(): Observable<TestDefinitions> {

    const datesString: string[] = [];
    datesString.push(moment().format(DateFormatConstants.YYYYMMDD));
    datesString.push(moment().format(DateFormatConstants.YYYYMMDD));
    const options = {params: new HttpParams().set('startEndDate', datesString.toString())};

    return this.http.get<TestDefinitions>(`${this.url}/test-definition/test-definitions`, options);
  }

  /** Get a booking for given student and course section. */
  getStudentBooking(id: number): Observable<StudentBooking> {
    return this.http.get<StudentBooking>(`${this.url}/student/student-bookings/${id}`);
  }

  // TODO: might not be needed
  getStudentBookings(): Observable<StudentBooking[]> {
    return undefined;
  }

  getAccommodations(): Observable<Accommodation[]> {
    return this.http.get<Accommodation[]>(`${this.url}/reference-data/student-accommodations`);
  }

  getActivityLogsForTestBooking(testBookingId: number): Observable<ActivityLog[]> {
    return this.http.get<ActivityLog[]>(`${this.url}/student/activity-logs/${testBookingId}`);
  }

  getActivityLogsForTestDefinition(testDefinitionId: number): Observable<ActivityLog[]> {
    return this.http.get<ActivityLog[]>(`${this.url}/test-definition/activity-logs/${testDefinitionId}`);
  }

  getPrintTestMaterials(): Observable<PrintTestMaterial[]> {
    return this.http.get<PrintTestMaterial[]>(`${this.url}/student/print-materials`);
  }

  getAccommodationChoices(): Observable<AccommodationChoices> {
    return this.http.get<AccommodationChoices>(`${this.url}/reference-data/accommodation-instructions-choices`);
  }

  getAdvisors(): Observable<Person[]> {
    return undefined;
  }

  /**
   * Ge the student courses with their meeting sections.
   *
   * NOTE:
   * Even if this is a GET method I used a POST in order to be able to send the student number
   * in the body of the request as it is consider private information. The other way, probably
   * better, would be to use the GET method and encrypt the student number, but this is a short ans secure way.
   *
   * @param params An object containing the student number and an array of session codes.
   */
  getStudentCourses(params: StudentSearch): Observable<Course[]> {
    return this.http.post<Course[]>(`${this.url}/student-info/courses`, params);
  }

  getCourseSections(courseCode: string, testDefSubtype: string): Observable<Course[]> {
    const options = {params: new HttpParams().set('courseCode', courseCode).set('testDefSubtype', testDefSubtype)};
    return this.http.get<Course[]>(`${this.url}/student-info/course-sections`, options);
  }

  getStudentInfo(atsStudentId: string): Observable<StudentInfo> {
    const options = { params: new HttpParams().set('atsStudentId', atsStudentId)};
    return this.http.get<StudentInfo>(`${this.url}/student-info`, options);
  }

  getAccommodatedStudentNames(searchVal: string): Observable<StudentName[]> {
    // This is a hack! ok for now. '99999' is the value sent if type-ahead doesn't have any char entered.
    if (searchVal === '99999') {
      return of([]);
    }

    const options = {params: new HttpParams().set('searchVal', searchVal)};
    return this.http.get<StudentName[]>(`${this.url}/student-info/names`, options);
  }

  createTestDefinitions(booking: ManualBooking) {
   return this.http.post(`${this.url}/manual-booking/create`, booking);
  }

  getAccommodationsByStudentId(studentId: string): Observable<StudentAccommodations> {
    const options = {params: new HttpParams().set('studentId', studentId)};
    return this.http.get<StudentAccommodations>(`${this.url}/student-accommodations`, options);
  }

  getAccommodationsByStudentAndCourse(accommodationSearch: AccommodationSearch): Observable<StudentCourseAccommodations> {
    return this.http.post<StudentCourseAccommodations>(`${this.url}/student-accommodations/accommodations`, accommodationSearch);
  }

  updateAccommodationsFromClockwork(rosiStudentNumber: string) {
    return this.http.post(`${this.url}/student-accommodations/update-accommodations-from-clockwork`,
      {value: rosiStudentNumber});
  }



  getCourseSpecificAccommodationsByBookingId(bookingId: number): Observable<StudentCourseAccommodations> {
    const options = {params: new HttpParams().set('bookingId', bookingId)};
    return this.http.get<StudentCourseAccommodations>(`${this.url}/student-accommodations/booking-accommodations`, options);
  }

  getActiveTestDefinitions(courseCode: string, sectionCode: string, sessionCode: string,
                           teachMethod: string, sectionNr: string, testType: string): Observable<SelectedTestDef[]> {

    const options = {params: {courseCode, sectionCode, sessionCode, teachMethod, sectionNr, testType}};
    return this.http.get<SelectedTestDef[]>(`${this.url}/test-definition/active-test-defs`, options);

  }

  getRosiCourseInstructors(courseCode: string, sectionCode: string, sessionCode: string,
                           teachMethod: string, sectionNr: string): Observable<any> {
    const options = {params: {courseCode, sectionCode, sessionCode, teachMethod, sectionNr}};
    return this.http.get<SelectedTestDef[]>(`${this.url}/student/bookings/instructors`, options);
  }

  getAccommodationConflicts(bookingId: string): Observable<AccommodationConflict[]> {
    return this.http.get<AccommodationConflict[]>(`${this.url}/student/student-conflictCheck/${bookingId}`);
  }

  getBookingsByStudentId(studentId: string): Observable<Bookings> {
    const options = {params: new HttpParams().set('studentId', studentId)};
    return this.http.get<Bookings>(`${this.url}/student/student-bookings`, options);
  }

  getBookingsGroupsByStudentId(studentId: string): Observable<CourseBookings[]> {
    const options = {params: new HttpParams().set('studentId', studentId)};
    return this.http.get<CourseBookings[]>(`${this.url}/student/student-bookings-groups`, options);
  }

  saveStaffNotes(staffNotes: StaffNotes): Observable<ActivityLog[]> {
    return this.http.post<ActivityLog[]>(`${this.url}/student/staff-notes`, staffNotes);
  }

  getTestDetails(testBookingId: string): Observable<AccommodationInstructions> {
    const options = {params: new HttpParams().set('testBookingId', testBookingId)};
    return this.http.get<AccommodationInstructions>(`${this.url}/test-definition/details`, options);
  }

  updateStudentBooking(updatedBookingDetails: EditBookingDetails) {
    return this.http.post<StudentBooking>(`${this.url}/student/update-booking`, updatedBookingDetails);
  }

  getDesignateAssignments(assignmentLevel: string): Observable<DesignateAssignment[]> {
    const options = {params: new HttpParams().set('assignmentLevel', assignmentLevel)};
    return this.http.get<DesignateAssignment[]>(`${this.url}/designate-assignment`, options);
  }

  saveDesignateAssignment(designates: DesignateAssignment) {
    return this.http.post(`${this.url}/designate-assignment`, designates);
  }

  deleteDesignateAssignment(designateBindingId: string, assignmentLevel: string) {
    const options = {params: new HttpParams().set('designateBindingId', designateBindingId)
                                             .set("assignmentLevel", assignmentLevel)};
    return this.http.delete(`${this.url}/designate-assignment/designate-binding`, options);
  }

  getActivityOfferOrgs(): Observable<ActivityOfferOrg[]> {
    return this.http.get<ActivityOfferOrg[]>(`${this.url}/designate-assignment/activity-offer-orgs`);
  }

  getRosiCourses(searchVal: string): Observable<Course[]> {
    const options = {params: new HttpParams().set('searchVal', searchVal)};
    return this.http.get<Course[]>(`${this.url}/designate-assignment/rosi-courses`, options);
  }

  validateDesignate(utorIdOrEmail: string): Observable<Designate> {
    const options = {params: new HttpParams().set('utorIdOrEmail', utorIdOrEmail)};

    return this.http.get<Designate>(`${this.url}/designate-assignment/designate-validation`, options);
  }

  getRooms(): Observable<Room[]> {
    return this.http.get<Room[]>(`${this.url}/reference-data/rooms`);
  }

  getBookingsByDateRange(startEndDate: string[]): Observable<Bookings> {
    // @ts-ignore
    const options = {params: new HttpParams().set('startEndDate', startEndDate)};
    return this.http.get<Bookings>(`${this.url}/student/bookings`, options);
  }

  getTestDefinitionsByDate(assessmentDate: string): Observable<TestDefinitions> {
    const options = {params: new HttpParams().set('assessmentDate', assessmentDate)};
    return this.http.get<TestDefinitions>(`${this.url}/test-definition/test-definitions-by-date`, options);
  }

  getInstrEmailingTestDefs(assessmentDate: string): Observable<TestDefinitions> {
    const options = {params: new HttpParams().set('assessmentDate', assessmentDate)};
    return this.http.get<TestDefinitions>(`${this.url}/instructor-emailing/test-definitions-by-date`, options);
  }

  findAllCourseContacts(testDefId: string): Observable<InstructorEmailContacts> {
    const options = {params: new HttpParams().set('testDefId', testDefId)};
    return this.http.get<InstructorEmailContacts>(`${this.url}/instructor-emailing/all-course-contacts`, options);
  }

  sendEmail(selectedEmailContacts: SelectedEmailContacts): Observable<EmailResp> {
    return this.http.post<EmailResp>(`${this.url}/instructor-emailing/email`, selectedEmailContacts);
  }

  getNotificationLog(testDefId: number): Observable<NotificationLog[]> {
    const options = {params: new HttpParams().set('testDefId', testDefId)};
    return this.http.get<NotificationLog[]>(`${this.url}/instructor-emailing/notification-logs`, options);
  }

  getTestDefinitionsByDateRange(startEndDate: string[]): Observable<TestDefinitions> {
    // @ts-ignore
    const options = {params: new HttpParams().set('startEndDate', startEndDate)};
    return this.http.get<TestDefinitions>(`${this.url}/test-definition/test-definitions`, options);
  }

  getTestDefinitions(selector: PeriodCourseSelector): Observable<TestDefinitions> {
    const courseCode = selector.courseCode;
    const sectionCode = selector.sectionCode;
    const teachMethod = selector.teachMethod;
    const sectionNr = selector.sectionNr;
    const startDate = selector.startDate;
    const endDate = selector.endDate;
    const options = {params: {courseCode, sectionCode, teachMethod, sectionNr, startDate, endDate}};
    return this.http.get<TestDefinitions>(`${this.url}/test-definition/test-definitions-for-course`, options);

  }

  getBookings(selector: PeriodCourseSelector): Observable<Bookings> {
    const courseCode = selector.courseCode;
    const sectionCode = selector.sectionCode;
    const teachMethod = selector.teachMethod;
    const sectionNr = selector.sectionNr;
    const startDate = selector.startDate;
    const endDate = selector.endDate;
    const studentId = selector.studentId;
    const options = {params: {courseCode, sectionCode, teachMethod, sectionNr, startDate, endDate, studentId}};
    return this.http.get<Bookings>(`${this.url}/student/bookings-for-course`, options);
  }


  getTestTypes(): Observable<TestType[]> {
    return this.http.get<TestType[]>(`${this.url}/reference-data/test-types`);
  }

  updateBookingSpace(selectedSpace: SpaceOrOnline): Observable<SpaceOrOnline> {
    return this.http.post<SpaceOrOnline>(`${this.url}/student//student-booking/space`, selectedSpace);

  }

  markAsException(bookingValidationId: number) {
    return this.http.post(`${this.url}/student/student-booking/conflict`, bookingValidationId);
  }

  undoMarkConflictAsException(bookingValidationId: number) {
    return this.http.post(`${this.url}/student/student-booking/excepted-conflict`, bookingValidationId);
  }

  getTestDefinitionByTestDefinitionId(testDefinitionId: number): Observable<WholeTestDefinition> {
    const options = {params: new HttpParams().set('testDefinitionId', testDefinitionId)};
    return this.http.get<WholeTestDefinition>(`${this.url}/test-definition/whole-test-definition`, options);
  }

  provisionStudent(rosiStudentNumber: number): Observable<any> {
    return this.http.post(`${this.url}/student/provision-student`, rosiStudentNumber);
  }

  getVersion(): Observable<any> {
    return this.http.get(`${this.url}/version`);
  }

  uploadExams(file: any): Observable<FileUploadResp> {

    const uploadedFile = new FormData();
    uploadedFile.append('file', file, file.name);

    return this.http.post<FileUploadResp>(`${this.url}/file-upload`, uploadedFile);
  }

  getCourses(coursePrefix: string): Observable<Course[]> {
    const options = {params: new HttpParams().set('coursePrefix', coursePrefix)};
    return this.http.get<Course[]>(`${this.url}/course/courses-by-session`, options);
  }

  createTestDef(testDef: TestDef) {
    return this.http.post(`${this.url}/test-definition/create-test-def`, testDef);
  }

  getTestDef(testDefId: number): Observable<TestDef> {
    const options = {params: new HttpParams().set('testDefId', testDefId)};
    return this.http.get<TestDef>(`${this.url}/test-definition`, options);
  }

  getTestDefByExamId(examId: number): Observable<TestDef> {
    const options = {params: new HttpParams().set('examId', examId)};
    return this.http.get<TestDef>(`${this.url}/test-definition/test-def-with-exam-id`, options);
  }

  updateTestDef(testDef: TestDef) {
    return this.http.post(`${this.url}/test-definition/update-test-def`, testDef);
  }

  updateTestDefWithTypes(testDef: TestDef) {
    return this.http.post(`${this.url}/test-definition/update-test-def-with-types`, testDef);
  }

  getBookingsByTestDefinitionId(testDefinitionId: number): Observable<SimpleBooking[]> {
    const options = {params: new HttpParams().set('testDefinitionId', testDefinitionId)};
    return this.http.get<SimpleBooking[]>(`${this.url}/student/bookings-by-testDefinitionId`, options);
  }

  moveBookingsToOtherTestDefinitions(bookings: SimpleBooking[]): Observable<StudentInfo[]> {
    return this.http.post<StudentInfo[]>(`${this.url}/student/bookings-to-be-moved`, bookings);
  }

  updateBookingStatus(bookingStatus: UpdatedBookingStatus): Observable<string> {
    return this.http.put<string>(`${this.url}/student/booking/update-booking-status`, bookingStatus);
  }

  exportTestDefToClockwork(testDefinitionId: number): Observable<number> {
    return this.http.post<number>(`${this.url}/test-definition/export-test-def`, {value: testDefinitionId});
  }

  deleteTestDef(testDefinitionId: number): Observable<string> {
    return this.http.post<string>(`${this.url}/test-definition/delete-test-def`, {value: testDefinitionId});
  }

  saveCourseContacts(courseContacts: CourseContacts, editContacts: boolean): Observable<AtsResp> {
    const options = {params: new HttpParams().set('editContacts', editContacts)};
    return this.http.post<AtsResp>(`${this.url}/instructor-emailing/course-contacts`, courseContacts, options);
  }

  getCourseContacts(courseContacts: CourseContacts): Observable<CourseContacts> {
    const options = this._setCourseContactsQueryParams(courseContacts);
    return this.http.get<CourseContacts>(`${this.url}/instructor-emailing/course-contacts`, options);
  }

  deleteCourseContacts(courseContacts: CourseContacts): Observable<any> {
    const options = this._setCourseContactsQueryParams(courseContacts);
    return this.http.delete<CourseContactsPk>(`${this.url}/instructor-emailing/course-contacts`, options);
  }

  // Sets CourseContact parameters as Http query parameters.
  private _setCourseContactsQueryParams(courseContacts: CourseContacts): {params: HttpParams} {

        return {params: new HttpParams()
                  .set('courseCode', courseContacts.course.course)
                  .set('sectionCode', courseContacts.course.sectionCode)
                  .set('teachMethod', courseContacts.course.teachMethod)
                  .set('sectionNumber', courseContacts.course.sectionNumber)
                  .set('levelOfStudy', courseContacts.levelOfStudy)};
  }

  getCourseContactList(): Observable<CourseContacts[]> {
    return this.http.get<CourseContacts[]>(`${this.url}/instructor-emailing/course-contact-list`);
  }

  getCoursePrefByLevelOfInstr(): Observable<CourseByLevelOfInstr> {
    return this.http.get<CourseByLevelOfInstr>(`${this.url}/course/course-prefix`);
  }

  getCourseByLevelOfInstr(courseCodePrefix: string): Observable<CourseByLevelOfInstr> {
    const options = { params: new HttpParams().set('courseCodePrefix', courseCodePrefix)};
    return this.http.get<CourseByLevelOfInstr>(`${this.url}/course/course-code`, options);
  }

  getCourseMeetingSecByLevelOfInstr(courseCodePrefix: string): Observable<CourseByLevelOfInstr> {
    const options = { params: new HttpParams().set('courseCodePrefix', courseCodePrefix)};
    return this.http.get<CourseByLevelOfInstr>(`${this.url}/course/course-meeting-sections`, options);
  }

  getCisSubmissions(startDate: string, endDate: string): Observable<CisSubmissions> {
    const options = {params: new HttpParams().set('startDate', startDate).set('endDate', endDate)};
    return this.http.get<CisSubmissions>(`${this.url}/cis-submissions`, options);
  }

  assignReviewer(reviewer: Reviewer): Observable<any> {
    return this.http.post(`${this.url}/cis-submissions/assign-reviewer`, reviewer);
  }

  unassignReviewer(reviewer: Reviewer): Observable<any> {
    return this.http.post(`${this.url}/cis-submissions/unassign-reviewer`, reviewer);
  }

  setReviewStatus(reviewStatus: ReviewStatus): Observable<any> {
    return this.http.post(`${this.url}/cis-submissions/review-status`, reviewStatus);
  }

  addAtsNoteForCisSubmission(atsNote: AtsNote) {
    return this.http.post(`${this.url}/cis-submissions/ats-notes-for-cis-submission`, atsNote);
  }

  getAtsNotesForCisSubmission(atsTestDefId: number): Observable<AtsNote[]> {
    const options = { params: new HttpParams().set('atsTestDefId', atsTestDefId)};
    return this.http.get<AtsNote[]>(`${this.url}/cis-submissions/ats-notes-for-cis-submission`, options);
  }

}
