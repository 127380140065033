import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChange,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import {AtsNote} from "../ats-note";
import {MatTableDataSource} from "@angular/material/table";
import {MatSort} from "@angular/material/sort";
import {CisSubmissionsUtils} from "../cis-submissions-utils";

@Component({
  selector: 'app-review-notes-table',
  templateUrl: './review-notes-table.component.html',
  styleUrls: ['./review-notes-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ReviewNotesTableComponent implements OnInit, OnChanges {

  @Input()
  reviewNotes: AtsNote[];

  displayColumns = ['note', 'userAndTimestamp'];

  dataSource: MatTableDataSource<AtsNote>;

  @ViewChild(MatSort, {static: true}) sort?: MatSort;

  ngOnInit(): void {

    this.dataSource.sortingDataAccessor = (data: AtsNote, sortHeaderId: string): string => {
      let columnVal: string;

      if (sortHeaderId === 'userAndTimestamp') {
        columnVal = this.Utils.formatDateTime(data.date);
      }

      return columnVal;
    }

    this.dataSource.sort = this.sort;
  }

  ngOnChanges(changes: SimpleChanges): void {
    const notes: SimpleChange = changes['reviewNotes'];

    if (notes && notes.currentValue && notes.currentValue !== notes.previousValue) {
      this.dataSource = new MatTableDataSource(this.reviewNotes);

      // The array comes sorted in ascending order. Reverse the order to show on top the most recent note.
      this.reviewNotes.reverse();
    }
  }

  protected readonly Utils = CisSubmissionsUtils;
}
