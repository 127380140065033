export class Statistics {

  rangeStartDate: string;
  rangeEndDate: string;
  totalTestDefsInCIS: number;
  reviewed: number;
  reviewedInstructorUpdated: number;
  pendingConfirmation: number;
  pendingConfirmationAwaitingFile: number;
  pendingConfirmationInstructorUpdated: number;
  notReviewed: number;
  notSubmittedInCis: number;

}
