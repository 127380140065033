<!-- Modal to display the statistics -->
<div class="modal-header">
  <h4 class="modal-title" id="modal-basic-title">Statistics</h4>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">

  <!-- Test def info -->
  <div class="margin-top-2-rem">
      You are viewing the statistics for <strong>{{statistics.rangeStartDate}} to {{statistics.rangeEndDate}}</strong>.
      They are not impacted by filters that you may have applied.
  </div>

  <div class="margin-top-2-rem">
    <div><strong>Total Test Definitions in CIS:</strong> {{statistics.totalTestDefsInCIS}} </div>
    <div><strong>Reviewed:</strong> {{statistics.reviewed}} </div>
    <div><strong>Reviewed - Instructor Updated:</strong> {{statistics.reviewedInstructorUpdated}} </div>
    <div><strong>Pending Confirmation:</strong> {{statistics.pendingConfirmation}} </div>
    <div><strong>Pending Confirmation - Awaiting File:</strong> {{statistics.pendingConfirmationAwaitingFile}} </div>
    <div><strong>Pending Confirmation - Instructor Updated:</strong> {{statistics.pendingConfirmationInstructorUpdated}} </div>
    <div><strong>Not Reviewed:</strong> {{statistics.notReviewed}} </div>
    <div><strong>Not Submitted in CIS:</strong> {{statistics.notSubmittedInCis}} </div>
  </div>

</div>

