import {Component} from '@angular/core';
import {
  ProvisionStudentModalComponent
} from "../../../booking/components/provision-student-modal/provision-student-modal.component";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: 'app-provision-student-masthead',
  templateUrl: './provision-student-masthead.component.html',
  styleUrls: ['./provision-student-masthead.component.scss']
})
export class ProvisionStudentMastheadComponent {

  constructor(private modalService: NgbModal) { }

  openStudentProvisionModal() {
    const modalRef = this.modalService.open(ProvisionStudentModalComponent).result.then((rosiStudentId) => {

      if (rosiStudentId) {
        // this.router.navigateByUrl('student-profile/' + rosiStudentId);
      }
    }, () => {
    });
  }
}
