<div class="test-details">

  <!-- TODO: NOT-USED. To be removed later if this method won't be needed. -->
  <!-- Buttons -->
  <div class="mt-3">
    <!-- View File(s) button -->
    <a *ngIf="testDetails && testDetails.requiresFileUpload && testDetails.files.length"
       class="btn btn-primary" href="{{sharepointFilesFolder}}"
       role="button" target="_blank" rel="noopener">
      View File(s)
    </a>
  </div>

  <!-- Test details found -->
  <div *ngIf="testDetails?.workflowStatus?.statusCode !== 'INSTRUCTOR_REJECTED'">

    <!-- TODO: NOT-USED. To be removed later if this method won't be needed. -->
    <!-- >>> Test Details from Instructor <<< -->
    <div class="test-details-from-instructor">
      <h3>Test Details from Instructor</h3>

      <!-- *** Scheduling *** -->
      <div class="mt-3">
        <h4>Scheduling</h4>
        <!-- Schedule is complete -->
        <div *ngIf="schedulingCompleted === true">
          <ul>
            <!-- Is this an online assessment? -->
            <li *ngIf="testDetails.assessmentOnlineInd === true">
              Online
            </li>

            <!-- Online Type Dropdown -->
            <li *ngIf="testDetails.assessmentOnlineInd === true && testDetails.onlineAssessmentTypeId">
              {{onlineAssessmentTypeMap.get(testDetails.onlineAssessmentTypeId)}}
              <span *ngIf="testDetails.quercusPermissionToAtsInd === true">{{querrusConsentMessage}}</span>
              <span *ngIf="testDetails.quercusPermissionToAtsInd === false">{{querrusNotConsentMessage}}</span>
            </li>

            <!-- How is this assessment being completed? -->
            <li *ngIf="testDetails.assessmentCompletionInd" [ngSwitch]="testDetails.assessmentCompletionInd">
              <!-- Online -->
              <span *ngSwitchCase="assessmentCompletionInd.ONLINE">
                Students will complete this exclusively online
              </span>
              <!-- Take Home -->
              <span *ngSwitchCase="assessmentCompletionInd.TAKE_HOME">
                Students will complete this assessment by downloading/printing the assessment and uploading their
                submission
              </span>
              <!-- In Person -->
              <span *ngSwitchCase="assessmentCompletionInd.IN_PERSON">
                Students will complete this online assessment in person
              </span>
            </li>

            <!-- Timing and Submission (Strict, Fixed or Flexible -->
            <li *ngIf="testDetails.assessmentTimingInd" [ngSwitch]="testDetails.assessmentTimingInd">
              <span *ngSwitchCase="assessmentTimingInd.Strict">Strict</span>
              <span *ngSwitchCase="assessmentTimingInd.Fixed">Fixed</span>
              <span *ngSwitchCase="assessmentTimingInd.Flexible">Flexible</span>
            </li>

            <!-- Is there a mandatory lecture before or after this assessment? -->
            <li *ngIf="testDetails.lecBeforeOrAfterAssessmentInd && testDetails.lecBeforeOrAfterAssessmentInd !== 'N'">
              {{testDetails.lecBeforeOrAfterAssessmentInd === 'B' ?
              'Lecture before the assessment' : 'Lecture after the assessment, student must return by '}}
                                        {{testDetails.lectureAfterReturnToClassBy | date:'yyyy-MM-dd h:mm a'}}
            </li>

            <!-- Is there a group component for this assessment? -->
            <li *ngIf="testDetails.groupComponentForAssessmentInd === true">
              Group component, student must return by
                                       {{testDetails.groupComponentReturnToClassBy | date:'yyyy-MM-dd h:mm a'}}
            </li>

            <!-- Special Instructions to Accommodated Testing Services (Optional) -->
            <li *ngIf="testDetails.specialInstructions">
              Special Instruction: {{testDetails.specialInstructions}}
            </li>
          </ul>
        </div>
        <!-- Schedule is incomplete -->
        <div *ngIf="schedulingCompleted === false">
          No scheduling details have been submitted yet.
        </div>
      </div>

      <!-- *** Test Details *** -->
      <div class="mt-3">
        <h4>Test Details</h4>
        <!-- Details are complete -->
        <div *ngIf="detailsCompleted === true">
          <!-- Are aids permitted? -->
          <ul *ngIf="testDetails.aidPermittedInd === true">
            <li *ngFor="let aid of testDetails.accommodationAids; let i = index">
              Aid {{i + 1}}: {{aidMap.get(aid.aidTypeId)}} <span *ngIf="aid.aidDetails">, {{aid.aidDetails}}</span>
            </li>
          </ul>

          <!-- Are questions answered on Scantron multiple choice sheets? -->
          <ul *ngIf="testDetails.scantronMultipleChoiceInd === true">
            <li>
              Scantron: {{scantronMap.get(testDetails.scantronVersionId)}}
                                       <span *ngIf="testDetails.scantronDetails">, {{testDetails.scantronDetails}}</span>
            </li>
          </ul>

          <!-- Is an audiovisual component required? / Audiovisual Component dropdown -->
          <ul *ngIf="testDetails.audiovisualRequiredInd === true">
            <li *ngFor="let av of testDetails.accommodationAudiovisuals; let i = index">
              <!-- AV {#}: {AV type/"Other" input} -->
              AV {{i + 1}}: {{audiovisualMap.get(av.audiovisualCompId)}}
                                   <span *ngIf="av.audiovisualDetailsForOther">, {{av.audiovisualDetailsForOther}}</span>
              <br/>
              <!-- AV {#} - How long to show each slide/clip: {input} -->
              <span *ngIf="av.audiovisualHowLongToShow">
                  AV {{i + 1}} - How long to show each slide/clip: {{av.audiovisualHowLongToShow}}
                <br/>
              </span>
              <!-- AV {#} - How many times to show each slide/clip: {Input} -->
              <span *ngIf="av.audiovisualHowManyTimesToShow">
                  AV {{i + 1}} - How many times to show each slide/clip: {{av.audiovisualHowManyTimesToShow}}
                <br/>
              </span>
              <!-- AV {#} - Total length: {input} -->
              <span *ngIf="av.audiovisualLength">
                 AV {{i + 1}} - Total length: {{av.audiovisualLength}}
                <br/>
              </span>
              <!-- AV {#} - When to administer: {input} -->
              <span *ngIf="av.audiovisualWhenShouldBeAdmin">
                 AV {{i + 1}} - When to administer: {{av.audiovisualWhenShouldBeAdmin}}
                <br/>
              </span>
            </li>
          </ul>
        </div>
        <!-- Details are incomplete -->
        <div *ngIf="detailsCompleted === false">
          No test details have been submitted yet.
        </div>
      </div>

      <!-- Printing Options -->
      <div class="mt-3">
        <h4>Printing Options</h4>
        <!-- Printing options are complete -->
        <div *ngIf="printingOptionsCompleted === true">
          <!-- Do you have a file to upload? -->
          <!-- -Yes, file upload -->
          <div *ngIf="testDetails.requiresFileUpload === true">
            <ul>
              <li *ngFor="let file of testDetails.files; let i = index">
                File {{i + 1}}: {{file.documentName}}
              </li>
            </ul>
          </div>
          <!-- -No, no file upload -->
          <div *ngIf="testDetails.requiresFileUpload === false">
            No file uploaded.
          </div>
          <!-- print related options -->
          <ul *ngIf="testDetails.printOptions">
            <!-- crowdmark -->
            <li *ngIf="testDetails.printOptions.crowdMark === true">Crowdmark</li>
            <!-- page size -->
            <li *ngIf="testDetails.printOptions.pageSize !== null">{{printPageSize(testDetails.printOptions.pageSize)}}</li>
            <!-- one or two sided -->
            <li *ngIf="testDetails.printOptions.twoSided !== null">{{printSide(testDetails.printOptions.twoSided)}}</li>
            <!-- colour settings: color or black-and-white -->
            <li *ngIf="testDetails.printOptions.colorSettings !== null">{{printColour(testDetails.printOptions.colorSettings)}}</li>
          </ul>
          <!-- Video URL -->
          <ul *ngIf="testDetails.links && testDetails.links.length">
            <li *ngFor="let link of testDetails.links; let i = index">
              URL {{i + 1}}: <a href="{{link.documentLocation}}" target="_blank" rel="noopener">{{link.documentLocation}}</a>
            </li>
          </ul>
        </div>
        <!-- Printing options are incomplete -->
        <div *ngIf="printingOptionsCompleted === false">
          No printing options have been submitted yet.
        </div>
      </div>
    </div>

    <br/>
    <!-- >>> Test Details from Student <<< -->
    <div class="test-details-from-student" *ngIf="this.NotShowBookingInfo !== true">
      <h3>Test Details from Student</h3>
      <div *ngIf="testDetails">
        <ul>
          <!-- Is this assessment being completed online? -->
          <li *ngIf="testDetails.bookingCompletedOnline === true">Student indicated that assessment being completed online</li>
          <li *ngIf="testDetails.bookingCompletedOnline === false">Student indicated that assessment is not online</li>
          <!-- Would you like to complete this online assessment in person with ATS? -->
          <li *ngIf="testDetails.bookingInPersonWithAts === true">In person with ATS</li>
        </ul>
      </div>
      <div *ngIf="!testDetails">
        No test details from student have been submitted yet.
      </div>
    </div>
  </div>

  <!-- To get the text from UX -->
  <div *ngIf="testDetails?.workflowStatus?.statusCode === 'INSTRUCTOR_REJECTED'">
    [The test definition was rejected by the instructor in CIS!]
  </div>

</div>
