<!-- Application header (U of T logo, name of the application, login/logout and breadcrumbs, notifications, errors) -->
<div class="app-header">
  <!-- Header Banner -->
  <div class="header-banner">
    <div class="header-logo"><div class="header-splitter"></div></div>
    <div class="header-title"><h1>Accommodated Testing Services</h1></div>
    <div class="header-user-name">
      <div class="user-name-control-container" *ngIf="!loggedOut">{{userName()}} | <a class="underline" (click)="logOut()">Logout </a></div>
      <div class="user-name-control-container" *ngIf="loggedOut"><a class="underline" href="https://uthrprod.service-now.com/sp?id=kb_article&sys_id=98f6084287ad8d14bcbfbaae8bbb35e6">
        Help</a> | <a class="underline" (click)="login()">Login </a></div>
    </div>
  </div>
  <!-- ATS Navbar -->
  <div class="ats-navigation d-flex justify-content-between ats-navigation" *ngIf="!loggedOut">
    <ul class="nav nav-pills">
      <li class="nav-item">
        <a class="nav-link" [ngClass]="{highlight: routeSelected === ''}" (click)="setRoute('')" routerLink="/" >Bookings Dashboard</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" [ngClass]="{highlight: routeSelected === 'test-definitions'}" (click)="setRoute('test-definitions')"
           routerLink="/test-definitions">Test Definitions Dashboard</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" [ngClass]="{highlight: routeSelected === 'manual-booking'}" (click)="setRoute('manual-booking')" routerLink="manual-booking">Manual Booking</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" [ngClass]="{highlight: routeSelected === 'designate-dashboard'}" (click)="setRoute('designate-dashboard')" routerLink="designate-dashboard">Designates</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" [ngClass]="{highlight: routeSelected === 'instructor-emailing'}" (click)="setRoute('instructor-emailing')" routerLink="instructor-emailing">Instructor Emailing</a>
      </li>

      <li class="nav-item">
        <a class="nav-link" [ngClass]="{highlight: routeSelected === 'cis-submissions'}" (click)="setRoute('cis-submissions')" routerLink="cis-submissions">CIS Submissions</a>
      </li>
    </ul>
    <!-- Feedback from external link -->
    <div class="user-experience-feedback"><a href="https://forms.office.com/r/84ky6Hnn1V" sis-external-link>Feedback </a></div>
  </div>
  <!-- ATS Notifications -->
  <!-- TODO: this is a placeholder for notification; should be hidden or removed from DOM if there are no notifications -->
  <div class="ats-notifications"></div>
</div>
