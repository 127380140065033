import {Component, Input, OnInit} from '@angular/core';
import {OAuthService} from 'angular-oauth2-oidc';
import {NavigationEnd, Router} from "@angular/router";
import {AuthService} from "../common/services/auth.service";

@Component({
  selector: 'app-header',
  templateUrl: './app-header.component.html',
  styleUrls: ['./app-header.component.scss']
})
export class AppHeaderComponent implements OnInit{

  @Input()
  loggedOut: boolean;

  routeSelected;

  constructor(private oAuthService: OAuthService, private router: Router, private authService: AuthService) { }

  ngOnInit(): void {

    // url change causes nav bar change
    this.router.events.subscribe(
      e => {
        if (e instanceof NavigationEnd) {
          const url = this.router.url;
          if (url.length > 0) {
            this.router.url.trim();
            const pieces: string[] = url.slice(1).split('/');

            switch (pieces[0]) {
              case 'student-booking':
                this.routeSelected = "";
                break;
              case 'test-definitions':
                this.routeSelected = "test-definitions";
                break;
              case 'add-test-def':
                this.routeSelected = "test-definitions";
                break;
              case 'upload-test-def':
                this.routeSelected = "test-definitions";
                break;
              case 'test-definition':
                this.routeSelected = "manual-booking";
                break;
              case 'manual-booking':
                this.routeSelected = "manual-booking";
                break;
              case 'designate-dashboard':
                this.routeSelected = "designate-dashboard";
                break;
              case 'instructor-emailing':
                this.routeSelected = "instructor-emailing";
                break;
              case 'cis-submissions':
                this.routeSelected = "cis-submissions";
                break;
              case '':
                this.routeSelected = '';
                break;

              // right now we have only three options in the head menu which can't cover all the business.Later we can specify more options
              default:
                this.routeSelected = '';
            }
          }
        }
      }
    );
  }

  public userName(): string {

    if (this.oAuthService.hasValidIdToken()) {
      // @ts-ignore
      return this.oAuthService.getIdentityClaims().name;
    } else {
      return '';
    }

  }

  setRoute(route: string) {
    this.routeSelected = route;
  }

  logOut(): void {
    // this.authenticationService.logOffRemoveHttpTokenAndShowLogOffPage();

    this.authService.logout();
  }

  login(): void {
    // this.authenticationService.login();

    this.authService.login();
  }

}
