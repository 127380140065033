import {CourseByLevelOfInstr} from "../model/types/course-by-level-of-instr";
import {CourseLevelOfInstr} from "../model/types/course-level-of-instr";
import {LevelOfStudy} from "./level-of-study.enum";
import {FormControl} from "@angular/forms";
import {EmailReminder} from "./email-reminder.enum";
import {CisSubmissionStatus} from "./cis-submission-status.enum";
import {TestDefinition} from "../model/types/test-definition";
import {EmailReminderType} from "./email-reminder-type.enum";
import {ContactInfo} from "./contact-info";
import {InstructorEmailContacts} from "./instructor-email-contacts";
import {EmailContactRec} from "./email-contact-rec.intf";
import {SelectedEmailContacts} from "./selected-email-contacts";

export class InstructorEmailingUtils {

  static levelOfStudyMap: Map<string, string> = new Map<string, string>([
    [LevelOfStudy.UNDERGRADUATE, 'Undergraduate'],
    [LevelOfStudy.GRADUATE, 'Graduate'],
    [LevelOfStudy.BOTH, 'Undergraduate & Graduate']
  ]);

  static levelOfStudies = [
    {label: 'Undergraduate', value: LevelOfStudy.UNDERGRADUATE},
    {label: 'Graduate', value: LevelOfStudy.GRADUATE},
    {label: 'Undergraduate & Graduate', value: LevelOfStudy.BOTH}
  ];

  static reversedLevelOfStudyMap: Map<string, string> = new Map<string, string>([
    ['Undergraduate', LevelOfStudy.UNDERGRADUATE, ],
    ['Graduate', LevelOfStudy.GRADUATE],
    ['Undergraduate & Graduate', LevelOfStudy.BOTH]
  ]);

  static contactTypeMap: Map<string, string> = new Map<string, string>([
    ['COURSE_COORDINATOR', 'Course Coordinator'],
    ['DEPARTMENT_ADMINISTRATOR', 'Department Administrator'],
    ['INSTRUCTOR', 'Instructor'],
    ['PROGRAM_COORDINATOR', 'Program Coordinator'],
    ['TEACHING_ASSISTANT', 'Teaching Assistant'],
    ['OTHER', 'Other']
  ]);

  static contactTypes = [
    {label: 'Course Coordinator', value: 'COURSE_COORDINATOR'},
    {label: 'Department Administrator', value: 'DEPARTMENT_ADMINISTRATOR'},
    {label: 'Instructor', value: 'INSTRUCTOR'},
    {label: 'Program Coordinator', value: 'PROGRAM_COORDINATOR'},
    {label: 'Teaching Assistant', value: 'TEACHING_ASSISTANT'},
    {label: 'Other', value: 'OTHER'}
  ];

  static validStatuses = [
    {label: 'All Statuses', value: ''},
    {label: 'Currently Valid', value: 'CURRENTLY_VALID'},
    {label: 'Expired', value: 'EXPIRED'}
  ];

  static cisSubmissionStatusVals: CisSubmissionStatus[] = [
    CisSubmissionStatus.STEP_3,
    CisSubmissionStatus.STEP_5,
    CisSubmissionStatus.NOT_SUBMITTED,
    CisSubmissionStatus.NOT_FOUND
  ]
  static cisSubmissionStatusMap: Map<CisSubmissionStatus, string> = new Map([
    [CisSubmissionStatus.STEP_3, 'Step 3'],
    [CisSubmissionStatus.STEP_5, 'Step 5'],
    [CisSubmissionStatus.NOT_SUBMITTED, 'Not Submitted'],
    [CisSubmissionStatus.NOT_FOUND, 'Not Exported to CIS']
  ]);

  static emailReminderVals: EmailReminder[] = [
    EmailReminder.INITIAL_REQUEST,
    EmailReminder.THREE_DAY_REMINDER,
    EmailReminder.ONE_DAY_REMINDER,
    EmailReminder.NONE
  ];
  static emailReminderMap: Map<EmailReminder, string> = new Map([
    [EmailReminder.INITIAL_REQUEST, 'Initial Request'],
    [EmailReminder.THREE_DAY_REMINDER, '3-day Reminder'],
    [EmailReminder.ONE_DAY_REMINDER, '1-day Reminder'],
    [EmailReminder.NONE, 'None']
  ]);

  static sendingActionVals: string[] = [
    'TO', 'CC', 'NONE'
  ];
  static sendingActionMap: Map<string, string> = new Map([
    ['TO', 'To'],
    ['CC', 'CC'],
    ['NONE', 'None']
  ]);

  static emailReminderTypeMap: Map<string, string> = new Map([
    [EmailReminderType.TO_INPUT_CIS_SUBMISSION, 'Reminder to input CIS submission'],
    [EmailReminderType.TO_APPLY_ACCOMMODATIONS, 'Reminder to apply accommodations']
  ]);

  public static mapCourseCodeLevelOfInstr(courseByLevelOfInstr: CourseByLevelOfInstr,
                                          levelOfStudyCtrl: FormControl,
                                          levelOfStudyEnum = LevelOfStudy): CourseLevelOfInstr[] {
    let courseCodeList: CourseLevelOfInstr[];
    if (levelOfStudyCtrl.value === levelOfStudyEnum.UNDERGRADUATE) {
      courseCodeList = courseByLevelOfInstr.undergraduateCourses;

    } else if (levelOfStudyCtrl.value === levelOfStudyEnum.GRADUATE) {
      courseCodeList = courseByLevelOfInstr.graduateCourses;

    } else if (levelOfStudyCtrl.value === levelOfStudyEnum.BOTH) {
      courseCodeList = courseByLevelOfInstr.graduateUndergraduateCourses;
    }

    return courseCodeList;
  }

  public static formatCourse(courseObj: CourseLevelOfInstr) {
    const comma = !courseObj.teachMethod ? '' : ', ';
    return `${courseObj.course?.trim()} ${courseObj.sectionCode?.trim() || ''}${comma} ${courseObj.teachMethod?.trim() || ''} ${courseObj.sectionNumber?.trim() || ''}`;
  }

  public static formatCourseForSorting(courseObj: CourseLevelOfInstr) {
    return `${courseObj.course?.trim()}${courseObj.sectionCode?.trim()}${courseObj.teachMethod?.trim()}${courseObj.sectionNumber?.trim()}`;
  }

  public static formatTestDefinitionForSorting(courseObj: TestDefinition) {
    return `${courseObj.academicActivityCode?.trim()} ${courseObj.sectionCode?.trim()} ${courseObj.teachMethod?.trim()}${courseObj.sectionNr?.trim()}`;
  }

  public static getSendEmailPageTitle(emailType: string): string {
    let title = '';

    if (emailType === EmailReminder.INITIAL_REQUEST) {
      title = 'Send Initial Request Email';

    } else if (emailType === EmailReminder.THREE_DAY_REMINDER) {
      title = 'Send 3-day Reminder Email';

    } else if (emailType === EmailReminder.ONE_DAY_REMINDER) {
      title = 'Send 1-day Reminder Email';
    }

    return title;
  }

  public static getSendEmailPageButtonText(emailType: string): string {
    let sendEmailButtonText = '';

    if (emailType === EmailReminder.INITIAL_REQUEST) {
      sendEmailButtonText = 'Send Initial Request Email';

    } else if (emailType === EmailReminder.THREE_DAY_REMINDER) {
      sendEmailButtonText = 'Send 3-day Reminder Email';

    } else if (emailType === EmailReminder.ONE_DAY_REMINDER) {
      sendEmailButtonText = 'Send 1-day Reminder Email';
    }

    return sendEmailButtonText;
  }

  // Choose Course Contacts & Select Sending Action text
  public static getChooseCourseContactsText(emailType: string) {
    return (emailType === EmailReminder.INITIAL_REQUEST) ?
      `For Initial Request Emails, the Sending Action column for course contacts from ROSI are defaulted to “To” and
       contacts added in ATS are defaulted to “None”. You can modify which course contacts to send emails “To” or “CC”
       by selecting from the Sending Action column, or if you do not wish to send an email to a course contact, you can
       select “None”. Please review the Sending Actions before sending the emails.`
        :
      `For ${this.emailReminderMap.get(EmailReminder[emailType])} Emails, the Sending Action column for course contacts from
       ROSI are defaulted to “To” and contacts added in ATS are defaulted to “CC”. You can modify which course contacts
       to send emails “To” or “CC” by selecting from the Sending Action column, or if you do not wish to send an email
       to a course contact, you can select “None”. Please review the Sending Actions before sending the emails.`;
  }

  public static buildTestDefContactsTableRecords(testDefId: string,
                                                 instructorEmailContacts: InstructorEmailContacts,
                                                 emailType: string): EmailContactRec[] {

    const emailContactRec: EmailContactRec[] = [];

    if (!instructorEmailContacts)  {
      return emailContactRec;
    }

    const instructors: ContactInfo[] = instructorEmailContacts.instructors || [];
    const coordinators: ContactInfo[] = instructorEmailContacts.coordinators || [];
    const localContacts: ContactInfo[] = instructorEmailContacts.localContacts || [];

    instructors.forEach((contactInfo: ContactInfo) => emailContactRec.push({
      testDefId: testDefId,
      sendingAction: new FormControl('TO'),
      contactName: contactInfo.fullName,
      contactType: 'Instructor (ROSI)',
      emailAddress: contactInfo.emailAddress
    }));

    coordinators.forEach((contactInfo: ContactInfo) => emailContactRec.push({
      testDefId: testDefId,
      sendingAction: new FormControl('TO'),
      contactName: contactInfo.fullName,
      contactType: 'Coordinator (ROSI)',
      emailAddress: contactInfo.emailAddress
    }));

    localContacts.forEach((contactInfo: ContactInfo) => emailContactRec.push({
      testDefId: testDefId,
      sendingAction: (emailType === EmailReminder.INITIAL_REQUEST) ? new FormControl('NONE') : new FormControl('CC'),
      contactName: contactInfo.fullName,
      contactType: contactInfo.contactType,
      emailAddress: contactInfo.emailAddress
    }));

    return emailContactRec;
  }

  public static buildBackendResp(testDefinition: TestDefinition,
                                 emailType: string,
                                 emailReminderTypeCtrl: FormControl,
                                 emailContactRecords: EmailContactRec[]): SelectedEmailContacts {
    // -- Prepare the data to send to the backend --
    let testDefinitionId: string = '';
    const selectedContacts: ContactInfo[] = [];

    // Get the test definition ID.
    if (testDefinition) {
      testDefinitionId = testDefinition.testDefinitionId.toString();
    }

    // Get all the contacts to send emails to.
    emailContactRecords.forEach((emailContactRec: EmailContactRec) => {

      if (emailContactRec.sendingAction.value === 'NONE') {
        return;
      } else {
        selectedContacts.push({
          firstName: null,
          lastName: null,
          fullName: emailContactRec.contactName,
          emailAddress: emailContactRec.emailAddress,
          contactType: emailContactRec.contactType,
          sendingAction: emailContactRec.sendingAction.value
        });
      }
    });

    // Create an SelectedEmailContacts object containing all the email info, to send to the backend.
    const selectedEmailContacts: SelectedEmailContacts = {
      testDefinitionId: testDefinitionId,
      courseName: this.formatTestDefinitionForSorting(testDefinition),
      sessionCode: testDefinition.sessionCode,
      assessmentDate: testDefinition.classStartDate.toString(),
      emailType: emailType,
      contacts: selectedContacts,
      emailReminderType: (!emailReminderTypeCtrl) ? '' : emailReminderTypeCtrl.value
    }

    return selectedEmailContacts;
  }

  // --- Success toaster messages ---

  // Create a message to be displayed as a success toast.
  //   E.g. You sent the 1-day Reminder Email (Reminder to input CIS submission)
  //        to course contacts for ECO101H LEC0101 (Test Definition ID: 1234).
  public static createSuccessToastMsg(emailType: string,
                                      emailReminderTypeCtrl: FormControl,
                                      testDefinition: TestDefinition): string {

    // Type of reminder email. Only for 1-day: 'Reminder to input CIS submission' or 'Reminder to apply accommodations'.
    const oneDayReminderType = (emailType === EmailReminder.ONE_DAY_REMINDER) ?
      `(${this.emailReminderTypeMap.get(EmailReminderType[emailReminderTypeCtrl.value])})` : '';

    return `You sent the ${this.emailReminderMap.get(EmailReminder[emailType])} Email
           ${oneDayReminderType} to course contacts for ${this.formatTestDefinitionForSorting(testDefinition)}
           (Test Definition ID: ${testDefinition.testDefinitionId}).`;
  }

  // --- Error messages ---

  // - General error message
  static errorMsg_SEND_EMAIL_ERROR: string =
    `The emails could not be sent to the course contacts for this test definition. Please try again.
     If the error persists, please submit a ticket via the <a href="https://uoft.me/esc" target="_blank">
     Enterprise Service Center Form <i class="fa fa-external-link-alt"></i></a>.`;

  // - Error message when trying to send emails without having any set to 'TO'
  static errorMsg_NO_SENDING_ACTION_HAS_TO: string =
    `Please ensure that at least one course contact’s Sending Action is set as “To” and try again.`;

  static infoMsg_NO_CONTACTS_FOUND_FOR_TEST_DEF = `You will need to add course contacts to this definition on
                         the <a href="/instructor-emailing/manage-course-contacts"> Manage Course Contacts</a> page.`;
  static infoMsg_TITLE_NO_CONTACTS_FOUND_FOR_TEST_DEF = 'This test definition has no contacts in ROSI or ATS';

}
