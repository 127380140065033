import {Component, Input} from '@angular/core';
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";
import {Statistics} from "../statistics";

@Component({
  selector: 'app-view-statistics-modal',
  templateUrl: './view-statistics-modal.component.html',
  styleUrls: ['./view-statistics-modal.component.scss']
})
export class ViewStatisticsModalComponent {

  @Input() statistics: Statistics;

  constructor(public activeModal: NgbActiveModal) {}
}
