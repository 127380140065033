import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {MatTableDataSource} from "@angular/material/table";
import {FormControl} from "@angular/forms";
import {LogService} from "../../common/services/log-service.service";
import {InstructorEmailingUtils} from "../instructor-emailing-utils";
import {TestDefinition} from "../../model/types/test-definition";
import {SelectionModel} from "@angular/cdk/collections";
import {CommonUtils} from "../../common/utils/common-utils";

@Component({
  selector: 'app-emailing-filter',
  templateUrl: './emailing-filter.component.html',
  styleUrls: ['./emailing-filter.component.scss']
})
export class EmailingFilterComponent implements OnInit, OnChanges {

  // Reference to the datasource of the Angular Material Table
  @Input() dataSource: MatTableDataSource<TestDefinition>;
  // Reference to the SelectionModel of the Angular Material Table
  @Input() selection: SelectionModel<TestDefinition>;

  // Holds the typed or selected value from the drop-down.
  courseFilter: string;
  atsTestDefIdFilter: string;
  numberOfBookingsFilter: string;
  cisSubmissionStatusFilter: string;
  instructorEmailStatusFilter: string;

  // Filters
  courseCtrl = new FormControl('');
  atsTestDefIdCtrl = new FormControl('');
  numberOfBookingsCtrl = new FormControl('');
  cisSubmissionStatusCtrl = new FormControl('');
  instructorEmailStatusCtrl = new FormControl('');

  constructor(private logger: LogService) {}

  ngOnInit(): void {

    this.courseCtrl.valueChanges.subscribe( (val: string) => {
      this.logger.debug(`=> value changed to: ${val}`, `${this.constructor.name}`);

      this.courseFilter = val;
      this.dataSource.filter = 'COURSE_CTRL_FILTER: ' + val;
    });

    this.atsTestDefIdCtrl.valueChanges.subscribe( (val: string) => {
      this.logger.debug(`=> value changed to: ${val}`, `${this.constructor.name}`);

      this.atsTestDefIdFilter = val;
      this.dataSource.filter = 'TEST_DEF_ID_CTRL_FILTER: ' + val;
    });

    this.numberOfBookingsCtrl.valueChanges.subscribe( (val: string) => {
      this.logger.debug(`=> value changed to: ${val}`, `${this.constructor.name}`);

      this.numberOfBookingsFilter = val;
      this.dataSource.filter = 'NUMBER_OF_BOOKINGS_CTRL_FILTER: ' + val;
    });

    this.cisSubmissionStatusCtrl.valueChanges.subscribe( (val: string) => {
      this.logger.debug(`=> value changed to: ${val}`, `${this.constructor.name}`);

      this.cisSubmissionStatusFilter = val;
      this.dataSource.filter = 'CIS_SUBMISSION_STATUS_CTRL_FILTER: ' + val;
    });

    this.instructorEmailStatusCtrl.valueChanges.subscribe( (val: string) => {
      this.logger.debug(`=> value changed to: ${val}`, `${this.constructor.name}`);

      this.instructorEmailStatusFilter = val;
      this.dataSource.filter = 'INSTRUCTOR_EMAIL_STATUS_CTRL_FILTER: ' + val;
    });
  }

  ngOnChanges(changes: SimpleChanges): void {

    // Once data source input is received from the parent, do the filtering.
    if (this.dataSource != null && changes['dataSource'].currentValue != null) {

      this.dataSource.filterPredicate = (data: TestDefinition, filter: string) => {

        this.logger.debug(`=> filter: ${filter}`, `${this.constructor.name}`);

        return this._filterByCourse(data, this.courseFilter)
          && this._filterByAtsTestDefId(data, this.atsTestDefIdFilter)
          && this._filterByNumberOfBookings(data, this.numberOfBookingsFilter)
          && this._filterByCisSubmissionStatus(data, this.cisSubmissionStatusFilter)
          && this._filterByLastEmailSent(data, this.instructorEmailStatusFilter);
      }
    }
  }

  _filterByCourse(data: TestDefinition, filter: string) {
    this._deselectAllSelectedRows();

    return !filter ? true : this.CommonUtils.checkMatch(this.Utils.formatTestDefinitionForSorting(data), filter);
  }

  _filterByAtsTestDefId(data: TestDefinition, filter: string) {
    this._deselectAllSelectedRows();

    return !filter ? true : this.CommonUtils.checkMatch(data.testDefinitionId.toString(), filter);
  }

  _filterByNumberOfBookings(data: TestDefinition, filter: string) {
    this._deselectAllSelectedRows();

    if (!filter) return true;

    return (filter === '0') ?
      data.numberOfApprovedBookings.toString() === filter : data.numberOfApprovedBookings.toString() != '0';
  }

  _filterByCisSubmissionStatus(data: TestDefinition, filter: string) {
    this._deselectAllSelectedRows();

    return (!filter) ? true : data.cisSubmissionStep === filter;
  }

  _filterByLastEmailSent(data: TestDefinition, filter: string) {
    this._deselectAllSelectedRows();

    return (!filter) ? true : data.emailType === filter;
  }

  // Deselect all selected rows in Instructor Emailing dashboard
  _deselectAllSelectedRows() {
    this.selection.clear();
  }

  resetFilter() {
    this.courseCtrl.setValue('');
    this.atsTestDefIdCtrl.setValue('');
    this.numberOfBookingsCtrl.setValue('');
    this.cisSubmissionStatusCtrl.setValue('');
    this.instructorEmailStatusCtrl.setValue('');

    // This will trigger the filtering refreshing.
    this.dataSource.filter = '';
  }

  protected readonly Utils = InstructorEmailingUtils;
  protected readonly CommonUtils = CommonUtils;
}
